import {LocaleLangTag} from "./localization/types";
import {LocalizationManager} from "./localization/LocalizationManager";
import {AppInfo} from "./AppInfo";
import RoutesManager from "./routing/RoutesManager";
import {NavigationManager} from "./navigation/NavigationManager";
import {App} from 'vue'
import {Router} from 'vue-router'
import {CoreInterface, CoreInternalInterface, EnvVars, TRunMode} from "./types";
import {ConfigManager} from "./config/ConfigManager";
import {TConfigData, TConfigDataNode} from "./config/types";
import DevelTools from "./devel/DevelTools";
import GenericLogOutput from "./logging/output/GenericLogOutput";
import {LoggingManager} from "./logging/LoggingManager";
import {ConnectManager} from "./connect/ConnectManager";

//@ts-expect-error - global variable
export const version = (typeof __CORE_VERSION__ === 'undefined') ? 'unknown' : __CORE_VERSION__

export const RunModes = {
    DEVELOPMENT: 'dev',
    PRODUCTION: 'prod',
    TEST: 'test',
} as const;

class NotBootedCore implements CoreInterface {
    public readonly __booted: boolean = false;
    public readonly version: string = version

    private fixOrFail = () => {
        if (window.__FeoAppCore__instance){
            console.warn('Core accessed from non-booted instance, but global instance exists, redirecting...')
            coreInstance = window.__FeoAppCore__instance
        }else{
            throw new Error('Core accessed before boot')
        }
    }

    get info(): AppInfo {
        this.fixOrFail()
        return coreInstance.info
    }

    get routes(): RoutesManager {
        this.fixOrFail()
        return coreInstance.routes
    }

    get nav(): NavigationManager {
        this.fixOrFail()
        return coreInstance.nav
    }

    get conf(): ConfigManager {
        this.fixOrFail()
        return coreInstance.conf
    }

    get log(): LoggingManager {
        this.fixOrFail()
        return coreInstance.log
    }

    get loc(): LocalizationManager {
        this.fixOrFail()
        return coreInstance.loc
    }

    get connect(): ConnectManager {
        this.fixOrFail()
        return coreInstance.connect
    }
}

//console.log('CoreRegistrar SETUP')
let coreInstance: CoreInterface = new NotBootedCore()
if (window.__FeoAppCore__instance) {
    console.warn('Second codebase of core imported!'
        +' Current version:' + version
        + ', Global version: ' + window.__FeoAppCore__instance.version
        + ". Check your imports, peers or Vite.optimizeDeps.exclude!")

    //we can assign global instance, but it can be notbooted and dangerous
    coreInstance = window.__FeoAppCore__instance
}

export { coreInstance as core }

const getCoreInternal = ():CoreInternalInterface => {
    if (!coreInstance.__booted) {
        throw new Error('Core not booted')
    }
    return coreInstance as CoreInternalInterface
}

export const CoreSetup = {
    async boot(env: EnvVars = {},
               router: Router | undefined = undefined,
               jsonConfigPath: string | undefined = undefined,
    ) {
        if (coreInstance.__booted) {
            throw new Error('CoreBoot second boot attempt')
        }

        const configManager = new ConfigManager(env)
        if (jsonConfigPath) {
            try {
                const localConfig = await configManager._loadConfigJson(jsonConfigPath)
                configManager._setLocalConfig(localConfig)
            }
            catch (e) {
                console.error('CoreSetup.boot failed to load config', jsonConfigPath, e)
            }
        }

        coreInstance = new CoreRegistrar(env, configManager)

        if (router) CoreSetup.initRouter(router)
    },
    initRouter(router: Router) {
        getCoreInternal()._setRouter(router)
    },
    initLocales(
        defaultLocale: LocaleLangTag,
        supportedLocales: LocaleLangTag[],
        messages: any,
        fallbackLocale: LocaleLangTag = 'en-US',
    ) {
        getCoreInternal()._setLocalizationManager(
            new LocalizationManager(messages,
                defaultLocale,
                supportedLocales,
                fallbackLocale,
                getCoreInternal().log.getCoreLogger('loc'),
            )
        )
    },
    wireApp(app: App) {
        getCoreInternal()._setApp(app)
    },
    addDefaults<T extends TConfigData>(defaults: T):void {
        getCoreInternal().conf.addDefaults<T>(defaults)
    },
    async loadConfig(envFileAppPath: string):Promise<TConfigDataNode> {
        return getCoreInternal().conf._loadConfigJson(envFileAppPath)
    },
}

export class CoreRegistrar implements CoreInternalInterface{
    public readonly __booted: boolean = true;
    public readonly version: string = version
    private readonly configManager: ConfigManager;
    private readonly appInfo: AppInfo;
    private readonly loggingManager: LoggingManager;

    private readonly navigationManager: NavigationManager;
    private localizationManager: LocalizationManager | null = null;
    private routesManager: RoutesManager | null = null;
    private vueApp: App | null = null
    private readonly connectManager: ConnectManager;

    constructor(envs: EnvVars = {}, configManager: ConfigManager)
    {
        if (window.__FeoAppCore__instance) {
            throw new Error('CoreRegistrar already booted')
        }
        window.__FeoAppCore__instance = this

        //config is crucial for setup, let him be first
        this.configManager = configManager

        this.appInfo = this.buildAppInfo(envs)

        let outputLogger
        if (this.appInfo.runMode !== RunModes.PRODUCTION) {
            outputLogger = DevelTools.getOutputLogger(this.configManager)
        }else{
            outputLogger = new GenericLogOutput()
        }

        this.loggingManager = new LoggingManager(outputLogger)
        this.configManager.setLogger(this.loggingManager.getCoreLogger('config'))
        this.navigationManager = new NavigationManager()
        this.connectManager = new ConnectManager()

        if (this.appInfo.runMode !== RunModes.PRODUCTION) {
            DevelTools.installDebugHooks(this)
        }
    }

    _setRouter(router: Router) {
        this.routesManager = new RoutesManager(router, this.loggingManager.getCoreLogger('routes'))
        if (this.appInfo.runMode === 'dev') {
            DevelTools.installDevLinkProxyRoute(this.routesManager, this.configManager)
        }
    }

    _setApp(app: App) {
        this.vueApp = app

        if (this.connectManager.getDefaultApiService()) {
            this.vueApp.config.globalProperties.$api =
                this.connectManager.getDefaultApiService().getAxiosInstance()
        }
    }

    _setLocalizationManager(lm: LocalizationManager) {
        this.localizationManager = lm
        if (this.vueApp) {
            this.vueApp.use(lm.getI18n())
        }else{
            console.warn('Vue app not wired, i18n cannot be installed!')
        }
    }

    private buildAppInfo(pEnv: EnvVars): AppInfo {
        let appMode = pEnv.MODE as string ?? 'prod'

        let runMode:TRunMode = pEnv.DEV ? RunModes.DEVELOPMENT : RunModes.PRODUCTION
        if (pEnv.TEST) {
            runMode = RunModes.TEST
        }
        return new AppInfo(appMode, runMode)
    }

    get info(): AppInfo {
        if (!this.appInfo) {
            throw new Error('AppInfo not initialized')
        }
        return this.appInfo
    }

    get routes(): RoutesManager {
        if (!this.routesManager) {
            throw new Error('RoutesManager not initialized')
        }
        return this.routesManager
    }

    get nav(): NavigationManager {
        if (!this.navigationManager) {
            throw new Error('NavigationManager not initialized')
        }
        return this.navigationManager
    }

    get conf(): ConfigManager {
        return this.configManager
    }

    get log(): LoggingManager {
        return this.loggingManager
    }

    get loc(): LocalizationManager {
        if (!this.localizationManager) {
            throw new Error('LocalizationManager not initialized')
        }
        return this.localizationManager
    }
    get connect():ConnectManager {
        return this.connectManager
    }

}