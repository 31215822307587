import {I18n} from 'vue-i18n'
import {Lang, QuasarLanguage} from 'quasar';
import {i18nLocaleMessagesLanguagesSet, types} from './i18n/types';
import {I18nManager} from './i18n/I18nManager';
import {LocaleLangTag} from './types';
import QuasarLocalizationManager from './quasar/QuasarLocalizationManager';
import {EventEmitter, CoreEventListener} from '../internals/EventEmitter';
import DateFnsManager from './date-fns/DateFnsManager';
import DateTool from './quasar/DateTool';
import LoggerInterface from "../logging/input/LoggerInterface";

enum eventNames {
    localeChanging = 'localeChanging',
    localeChanged = 'localeChanged'
}
type ChangeEvent = CoreEventListener<{ to: LocaleLangTag, from:LocaleLangTag }>


export class LocalizationManager
{
    private currentLocaleFull: LocaleLangTag;
    private fallbackLocaleFull: LocaleLangTag;
    private i18nManager: I18nManager;
    private supportedLocales: LocaleLangTag[];
    private quasarManager: QuasarLocalizationManager;

    private event:EventEmitter = new EventEmitter()
    private readonly dateFnsManager: DateFnsManager;
    private readonly _dateTool: DateTool;
    private logger: LoggerInterface;

    /**
     *
     * @param messages
     * @param activeLocaleFull if null then the browser locale is used
     * @param supportedLocales full locale names
     * @param fallbackLocaleFull
     * @param logger
     */
    constructor(messages: i18nLocaleMessagesLanguagesSet,
                activeLocaleFull: LocaleLangTag | undefined = undefined,
                supportedLocales: LocaleLangTag[] = [ 'cs-CZ', 'en-US' ],
                fallbackLocaleFull:LocaleLangTag  = 'en-US',
                logger: LoggerInterface
    )
    {
        this.logger = logger
        //no input -> try to get the browser locale
        if (activeLocaleFull == undefined ||  !activeLocaleFull){
            activeLocaleFull = Lang.getLocale()
            if (activeLocaleFull){
                this.logger.debug('Browser locale detected: ', activeLocaleFull)
            }else{
                activeLocaleFull = fallbackLocaleFull
                this.logger.warn('No locale detected, fallback locale used: ', activeLocaleFull)
            }
        }else{
            //console.debug('Locale set: ', activeLocaleFull)
        }

        this.supportedLocales = supportedLocales
        this.currentLocaleFull = activeLocaleFull
        this.fallbackLocaleFull = fallbackLocaleFull

        this.i18nManager = new I18nManager(activeLocaleFull, fallbackLocaleFull, messages, this.logger)
        this.quasarManager = new QuasarLocalizationManager(activeLocaleFull, this.logger)
        this.dateFnsManager = new DateFnsManager(activeLocaleFull, this.logger)
        this.setCurrentLocale(activeLocaleFull)
        
        this._dateTool = new DateTool(this.dateFnsManager)
    }

    getCurrentLocale(): LocaleLangTag {
        return this.currentLocaleFull
    }

    public async setCurrentLocale(localeFull: LocaleLangTag): Promise<void>
    {
        if (!this.supportedLocales.includes(localeFull)){
            this.logger.warn('Unsupported locale: ', localeFull)
            return
        }
        const eventData = {
            to: localeFull,
            from: this.currentLocaleFull
        }
        this.event.emit(eventNames.localeChanging, eventData)
        //from slowest to fastest:
        await this.quasarManager.setLocale(localeFull)
        await this.dateFnsManager.setLocale(localeFull)
        await this.i18nManager.setLocale(localeFull)
        this.currentLocaleFull = localeFull
        this.event.emit(eventNames.localeChanged, eventData)
    }

    public appendMessages(locale: LocaleLangTag, messages: types): void {
        this.i18nManager.appendMessages(locale, messages)
    }

    public getI18n(): I18n {
        return this.i18nManager.getI18n()
    }

    public t(path: string, context: any = null): string {
        return this.i18nManager.t(path, context)
    }

    getFns(): DateFnsManager {
        return this.dateFnsManager
    }

    /**
     * Returns the current Quasar language pack
     * Usefull with some quasar components
     */
    getQuasar():QuasarLanguage
    {
        return this.quasarManager.get()
    }

    onLocaleChanging(callback: ChangeEvent): void {
        this.event.register(eventNames.localeChanging, callback)
    }
    onLocaleChanged(callback: ChangeEvent): void {
        this.event.register(eventNames.localeChanged, callback)
    }


    get dateTool(): DateTool {
        return this._dateTool;
    }
}

export default LocalizationManager
