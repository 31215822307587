import DateFnsManager from "../date-fns/DateFnsManager";


export class DateTool
{
    constructor(private dateFnsManager:DateFnsManager) {
    }

    private getShortFormat(): string
    {
        return this.dateFnsManager.get().formatLong.date({width: 'short' })
    }

    /**
     * Renders date to string in common short format
     * It's shoud be compatible with i18n d() - if not, use this function for parse support
     * @param date
     */
    public render(date: Date): string
    {
        return this.dateFnsManager.format(
            date,
            this.getShortFormat()
        )
    }

    /**
     * Parses date from string
     * It's shoud be compatible with i18n d() - if not, use this function for parse support
     * @param dateString
     */
    public parse(dateString: string): Date
    {
        return this.dateFnsManager.parse(
            dateString,
            this.getShortFormat()
        )
    }


}

export default DateTool;