import {LoggerInterface} from "../../input/LoggerInterface";
import {LogLevel} from "../../LogLevel";

declare type logFn = (msg: string, ...args: any[]) => void

enum ConsoleLevel {
    LOG = 'log',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
    DEBUG = 'debug'
}

class WindowConsoleFn {
    log: logFn
    info: logFn
    warn: logFn
    error: logFn
    debug: logFn

    constructor(l: logFn, i: logFn, w: logFn, e: logFn, d: logFn) {
        this.log = l
        this.info = i
        this.warn = w
        this.error = e
        this.debug = d
    }

    static readCurrent(): WindowConsoleFn {
        return new WindowConsoleFn(
            window.console.log,
            window.console.info,
            window.console.warn,
            window.console.error,
            window.console.debug
        )
    }

    public setThis() {
        window.console.log = this.log
        window.console.info = this.info
        window.console.warn = this.warn
        window.console.error = this.error
        window.console.debug = this.debug
    }
}

/**
 * 1) Provides ability to hook global console.*() to specified logger
 * 2) By using this object log functions you bypass hooked logger and write directly to console
 */
export class GlobalConsole
{
    public static windowConsoleNativeFn: WindowConsoleFn|null = null
    public static windowConsoleLoggerFn: WindowConsoleFn|null = null

    /**
     * Hook global console.*() to specified logger
     * @param logger
     */
    public static setLogger(logger: LoggerInterface)
    {
        this.ensureInitialized()

        GlobalConsole.windowConsoleLoggerFn = new WindowConsoleFn(
            logger.log.bind(logger, LogLevel.INFO),
            logger.log.bind(logger, LogLevel.NOTICE),
            logger.log.bind(logger, LogLevel.WARNING),
            logger.log.bind(logger, LogLevel.ERROR),
            logger.log.bind(logger, LogLevel.DEBUG)
        )
        GlobalConsole.windowConsoleLoggerFn.setThis()
    }

    public static ensureInitialized(): void
    {
        if (GlobalConsole.windowConsoleNativeFn === null) {
            GlobalConsole.windowConsoleNativeFn = WindowConsoleFn.readCurrent()
        }
    }

    public static restoreGlobalConsole(): void
    {
        if (GlobalConsole.windowConsoleNativeFn === null) {
            throw new Error('GlobalConsole not saved!')
        }
        GlobalConsole.windowConsoleNativeFn.setThis()
    }

    /**
     * Log message to console, bypassing hooked logger
     * @param logLevel
     * @param msg
     * @param args
     */
    public static logMessage(logLevel: ConsoleLevel, msg: string, ...args: any[]): void
    {
        this.ensureInitialized()
        const logFn = GlobalConsole.windowConsoleNativeFn
        if (logFn === null) throw new Error('GlobalConsole not initialized') //proforma

        switch (logLevel) {
            case ConsoleLevel.ERROR: logFn.error(msg, ...args);  break;
            case ConsoleLevel.WARN:  logFn.warn(msg, ...args);   break;
            case ConsoleLevel.INFO:  logFn.info(msg, ...args);   break;
            case ConsoleLevel.LOG:   logFn.log(msg, ...args);   break;
            case ConsoleLevel.DEBUG: logFn.debug(msg, ...args);  break;
            default: throw new Error('Unknown log level')
        }

    }

    public static log(msg: string, ...args: any[]):   void { GlobalConsole.logMessage(ConsoleLevel.LOG, msg, ...args) }
    public static info(msg: string, ...args: any[]):  void { GlobalConsole.logMessage(ConsoleLevel.INFO, msg, ...args) }
    public static warn(msg: string, ...args: any[]):  void { GlobalConsole.logMessage(ConsoleLevel.WARN, msg, ...args) }
    public static error(msg: string, ...args: any[]): void { GlobalConsole.logMessage(ConsoleLevel.ERROR, msg, ...args) }
    public static debug(msg: string, ...args: any[]): void { GlobalConsole.logMessage(ConsoleLevel.DEBUG, msg, ...args) }
}

export default GlobalConsole;