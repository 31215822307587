import {LogLevel} from "../logging/LogLevel";
import {CoreRegistrar} from "../Core";
import DevConsoleLogOutput from "../logging/output/DevConsoleLogOutput";
import RoutesManager from "../routing/RoutesManager";
import DevLinkProxyPage from "./DevLinkProxyPage";
import ConfigManager from "../config/ConfigManager";
import DevLinkBuilder from "./DevLinkBuilder";
import GlobalConsole from "../logging/output/Global/GlobalConsole";

export class DevelTools {

    public static getOutputLogger(conf: ConfigManager)
    {
        const dlb = new DevLinkBuilder(conf)
        const devLinkProxyUrl = this.getDevLinkProxyAbsUrl(
            window.location.origin + conf.env.getEnvPublicPathBase()
        )
        return new DevConsoleLogOutput(dlb, devLinkProxyUrl)
    }

    public static installDebugHooks(core:CoreRegistrar): void {
        const logger = core.log.getCoreLogger('debugHelp', LogLevel.DEBUG)
        //potlacit access na globalni objekty
        const w = (window as Window)
        w.feo = () => {
            const color = '#db0d5b'
            logger.notice("%c%s",
                "color: " + color + "; font: 1.2rem/1 Tahoma;",
                'Welcome to FEO debug help\n'
                + ' » feoConfig() - show Vue configuration object\n'
                + ' » feoRoutes() - show all registered routes\n'
                + ' » feoNav() - show default navigationTree\n'
            )
            return 'OK'
        }
        w.feoConfig = () => {
            //logger.notice('[FEO] Vue config:', core.this.vueApp?.config.globalProperties.$config.configuration)
            logger.notice('[FEO] Env:', core.conf.env)
            return 'OK'
        }
        w.feoRoutes = () => {
            logger.notice('[FEO] Routes:', core.routes.getRouter().getRoutes())
            return 'OK'
        }
        w.feoNav = () => {
            logger.notice('[FEO] Nav:', core.nav.getDefaultTree())
            return 'OK'
        }
    }

    public static installDevLinkProxyRoute(r: RoutesManager, conf:ConfigManager): void {
        const dlb = new DevLinkBuilder(conf)
        r.addRouteLeaf(
            '__devLink__',
            '/dev-link',
            DevLinkProxyPage(dlb)
        )
    }

    private static getDevLinkProxyAbsUrl(urlBase:string): string
    {
        return urlBase.replace(/\/$/, '') //remove trailing slash, double slash is different route
                + '/dev-link?file=%f&line=%l'
    }
}

export default DevelTools;