import {LogOutputInterface} from './LogOutputInterface';
import {getLogLevelName, LogLevel} from '../LogLevel';
import {getFeoSymbol} from '../assets/feo';
import {LogItemInterface} from '../LogItemInterface';
import {StackParser} from '../stack/StackParser';
import {StackSourceMapper} from '../stack/StackSourceMapper';
import {StackItem} from '../stack/StackItem';
import GlobalConsole from './Global/GlobalConsole';
import DevLinkBuilder from "../../devel/DevLinkBuilder";
import {Context} from "../Context";


class WindowConsoleFn {
    log: any
    info: any
    warn: any
    error: any
    debug: any

    constructor(l: any, i: any, w: any, e: any, d: any) {
        this.log = l
        this.info = i
        this.warn = w
        this.error = e
        this.debug = d
    }
}

export class DevConsoleLogOutput implements LogOutputInterface
{
    private logQueue: LogItemInterface[] = [];
    private queueProcessing: boolean = false;
    private devLinkBuilder: DevLinkBuilder;
    private readonly devLinkProxyTemplateLink: string|null;
    constructor(devLinkBuilder:DevLinkBuilder, devSourceProxyUrl: string|null)
    {
        this.devLinkBuilder = devLinkBuilder
        this.devLinkProxyTemplateLink = devSourceProxyUrl
    }

    private filterStackFromUseless(stack: StackItem[]): StackItem[]
    {
        stack = this.cutStackToRegexp(stack, /[\\/]src[\\/]logging[\\/]/)
        //if not developing this package:
        stack = this.cutStackToRegexp(stack, /[\\/]feo-app-core[\\/]src[\\/]/)
        return stack
    }

    private cutStackToRegexp(stack: StackItem[], regexp: RegExp): StackItem[]
    {
        let cutFrom = 0
        const foundIndex = stack.findIndex(
            function (item: StackItem) {
                return item.srcFilePath.match(regexp)
            }
        )
        if (foundIndex !== -1 ){
            cutFrom = foundIndex + 1
        }
        if (cutFrom > 0){
            stack = stack.slice(cutFrom)
            //replay this rule if match multiple times
            stack = this.cutStackToRegexp(stack, regexp)
        }
        return stack
    }

    private isFrameworkStack(item: StackItem): boolean
    {
        const matchString = [
            'node_modules/@vue/',
            'node_modules/pinia/',
            'node_modules/formvuelatte/'
        ]
        for (const string of matchString){
            if (item.srcFilePath.indexOf(string) >= 0){
                return true
            }
        }
        return false;
    }

    private cutStackFrameworks(stack: StackItem[]): StackItem[]
    {
         while (stack.length > 0 && this.isFrameworkStack(stack[0])){
            stack.shift()
         }
        return stack
    }

    private applyStackDevVars(stack: StackItem[]): StackItem[]
    {
        for (const item of stack) {
            item.srcFilePath = this.devLinkBuilder.applySourceMapping(item.srcFilePath)
        }
        return stack
    }

    private async getSourceCodeInfo(stackRaw:string): Promise<StackItem[]>
    {
        const baseStack =  StackParser.parse(stackRaw)
        //GlobalConsole.log('baseStack', baseStack)
        const mappedStack = await StackSourceMapper.mapStack(baseStack);
        //GlobalConsole.log('mappedStack', mappedStack)
        let cutStack = this.filterStackFromUseless(mappedStack)
        cutStack = this.cutStackFrameworks(cutStack)
        //GlobalConsole.log('cutStack', cutStack)
        return this.applyStackDevVars(cutStack)
    }

    private getDevLink(item:StackItem): string
    {
        if (this.devLinkProxyTemplateLink){
            return this.devLinkProxyTemplateLink.replace('%f', item.srcFilePath).replace('%l', item.srcFileLine.toString())
        }else{
            return this.devLinkBuilder.buildDevLink(item.srcFilePath, item.srcFileLine.toString())
        }
    }

    private getDevMiniPart(item:StackItem): string
    {
        return item.srcFilePath.split('/').slice(-6).join('\\') + ':' + item.srcFileLine
    }

    private logLevelToColor(logLevel: LogLevel)
    {
        switch (logLevel)
        {
            case LogLevel.DEBUG:
                return '#8598c0'
            case LogLevel.INFO:
                return '#63a2ff'
            case LogLevel.NOTICE:
                return '#00ff8a'
            case LogLevel.WARNING:
                return '#ff8b05'
            case LogLevel.ERROR:
                return '#f00'
            case LogLevel.CRITICAL:
                return '#f00'
            case LogLevel.ALERT:
                return '#f00'
            case LogLevel.EMERGENCY:
                return '#f00'
            default:
                return 'black'
        }
    }

    public log(logItem: LogItemInterface): void
    {
        Error.stackTraceLimit = 150;//pozorovano az 132, ale nic analyticky zajimaveho
        const err = new Error()
        if (Error.captureStackTrace) { //cut stack to this.log
            Error.captureStackTrace(err, this.log);
        }
        logItem._stackRaw = (err).stack
        const filtered = this.applyFilters(logItem)
        if (!filtered){
            return
        }else {
            logItem = filtered
        }
        //GlobalConsole.debug("stack", logItem._stackRaw)
        //we must queue logs, and process them in correct order
        this.logQueue.push(logItem)
        this.processQueue().then(r => {})
    }

    private async processQueue(): Promise<void>
    {
        if (this.queueProcessing)
        {
            return
        }
        this.queueProcessing = true
        while (this.logQueue.length > 0)
        {
            if (this.logQueue.length>300) {
                GlobalConsole.warn('logQueue too long (300+), clearing to last 20 items')
                this.logQueue = this.logQueue.slice(-20)
            }
            const logItem = this.logQueue.shift()
            if (logItem)
            {
                await this.logProcess(logItem)
            }
        }
        this.queueProcessing = false
    }

    private applyFilters(logItem: LogItemInterface): LogItemInterface|null
    {
        if (logItem.message && logItem.message.startsWith('[Vue ')){
            logItem.context = new Context('Vue', null)
        }
        if (logItem.message && logItem.message.startsWith('[intlify ')){
            logItem.context = new Context('intlify', null)
        }
        //if msg starts with [Vue warn] supress stack, is useless:
        if (logItem.message && logItem.message.startsWith('[Vue warn]')){
            //logItem.level = LogLevel.WARNING
            return null
        }
        if (logItem.message && logItem.message.startsWith('[intlify]')){
            //logItem.level = LogLevel.WARNING
            return null
        }
        return logItem
    }

    private async logProcess(logItem: LogItemInterface): Promise<void>
    {
        await this.getSourceCodeInfo(logItem._stackRaw ?? '')
        .then((stack) => {
            const finalLink = stack.length>0 ? this.getDevLink(stack[0]) : 'unknown'
            const miniPart = stack.length>0 ? this.getDevMiniPart(stack[0]) : 'unknown'
            const ll = getLogLevelName(logItem.level)
            const color = this.logLevelToColor(logItem.level)
            const symbolStyle = getFeoSymbol(1, 20)
            const colorTime = '#dedede'
            const colorSrcBg = color//'#6c5256'
            const colorSrcBorder = color//'#da7a8a'

            let showStack = false
            let logFn = GlobalConsole.log
            switch (logItem.level){
                case LogLevel.DEBUG: logFn = GlobalConsole.debug; break
                case LogLevel.NOTICE: logFn = GlobalConsole.info; break
                case LogLevel.WARNING: logFn = GlobalConsole.warn; showStack=true; break
                case LogLevel.ERROR: logFn = GlobalConsole.error; showStack=true; break
            }

            if (logItem.message && logItem.message.startsWith('[vite] hot updated')){
                //parse
                const hotUpdateMatch = logItem.message.match(/^\[vite\] hot updated: (.*)/)
                if (hotUpdateMatch && hotUpdateMatch[1]){
                    StackSourceMapper.invalidateCache(hotUpdateMatch[1])
                }
            }

            //calaculate random number from hash of logItem.context.toString() to get color
            let hash = 0;
            for (let i = 0; i < logItem.context.toString().length; i++) {
                hash = logItem.context.toString().charCodeAt(i) + ((hash << 5) - hash);
            }
            //calulate color of bg from hash, color of fg complemntary to be in contrast
            const colorCtx = '#' + (hash & 0x00FFFFFF).toString(16).toUpperCase().padStart(6, '0')
            const colorCtxBg = '#' + (0xFFFFFF ^ hash & 0x00FFFFFF).toString(16).toUpperCase().padStart(6, '0')

            const hiddenLinkStyle = 'background-color: ' + colorSrcBg
                + '; font-size: 0.012em; line-height:8px; font-family: "Arial Narrow";'
                + 'border-radius: 10px; border: 2px solid ' + colorSrcBorder;

            let appendix = ''
            let appendixStyle = ''
            if (showStack){
                appendixStyle = 'color: gray; font-size: 10px;'
                for(const item of stack.slice(1)){//skip first, as its already in message
                    //const devLink = this.getDevSourceCodeTemplateLink(stack[0].srcFilePath, stack[0].srcFileLine)
                    appendix += item.srcFilePath + ':' + item.srcFileLine + ' ' + item.functionName + '\n'
                }
            }

            logFn(
            //globbalConsole.log(
                '%c%s' + '(%c%s%c) ' + '%c%s ' + '%c   %s ' + '%c%s'
                + '%c\n%s'
                + '%c\n%s' //apendix
                ,
                symbolStyle + ';color:' + color + '; font-size: 16px;', ll,
                'color:' + colorCtx + '; background-color: '+colorCtxBg+'; font-size: 14px;', logItem.context.toString(),
                ';color:' + color + '; font-size: 16px;',
                'color:' + colorTime + '; font-size: 14px;', logItem.timestamp.toISOString(),
                'color: gray; text-decoration: none; font-size: 10px;', miniPart,
                hiddenLinkStyle, finalLink,
                'padding-left: 20px;' + 'color:' + color + '; font-size: 16px;', logItem.message,
                appendixStyle, appendix,
                ...logItem.args
            )
        })
    }

}

export default DevConsoleLogOutput;
