import {ContextInterface} from "./ContextInterface";

export class Context implements ContextInterface
{
    name: string;
    parentContext: ContextInterface | null;

    constructor(name: string, parentContext: ContextInterface | null = null)
    {
        this.name = name;
        this.parentContext = parentContext;
    }

    toString(): string
    {
        if (this.parentContext)
        {
            return `${this.parentContext.toString()}::${this.name}`;
        }
        return this.name;
    }
}