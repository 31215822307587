import NavigationNodeInterface from "./NavigationNodeInterface";

export class NavigationNode implements NavigationNodeInterface
{
    public routeName: string
    public nameTransId: string|null
    public icon: string|null
    public sortIndex: number
    public meta: object
    public nodeId: string
    public children: NavigationNodeInterface[]
    public readonly lazy: boolean
    private readonly _lazyLoader: any = null

    public routeParams: object = {}
    public translateParams: object = {}

    constructor(routeName: string,
                nameTransId: string|null = null,
                icon: string|null = null,
                sortIndex: number = 0,
                lazyLoader: any = null,

                meta: object = {},
                nodeId: string|null = null,
                children: NavigationNodeInterface[] = [])
    {
        this.routeName = routeName
        this.nameTransId = nameTransId
        this.icon = icon
        this.sortIndex = sortIndex
        this.meta = meta
        this.nodeId = nodeId ?? routeName
        this.children = children

        if (lazyLoader !== null) {
            this.lazy = true
            this._lazyLoader = lazyLoader
        }else{
            this.lazy = false
        }

        if (!nodeId){
            this.nodeId = routeName
        }
        if (!nameTransId){
            this.nameTransId = routeName
        }
    }

    public async _lazyLoad(): Promise<NavigationNodeInterface[]> {
        if (this._lazyLoader === null) {
            throw new Error("LazyLoader not set for node: " + this.nodeId)
        }
        let newNodes = await this._lazyLoader(this)
        return newNodes
    }

    public getName(localeCallback: any): string
    {
        if (!this.nameTransId){
            return this.routeName
        }
        //if first char is ':' -> don't translate
        const firstChar = this.nameTransId ? this.nameTransId.charAt(0) : ''
        if (firstChar === ':') {
            return this.nameTransId.substring(1)
        }
        return this.nameTransId
            ? localeCallback(this.nameTransId,this.translateParams)
            : this.routeName
    }

}

export default NavigationNode