import {getLogLevelName, LogLevel} from "../LogLevel";
import LogOutputInterface from "./LogOutputInterface";
import {LogItemInterface} from "../LogItemInterface";
import GlobalConsole from "./Global/GlobalConsole";

export class GenericLogOutput implements LogOutputInterface
{

    log(logItem:LogItemInterface): void
    {
        const ll = getLogLevelName(logItem.level)
        let targetFn
        switch (logItem.level){
            case LogLevel.DEBUG:
                targetFn = GlobalConsole.debug
                break
            case LogLevel.WARNING:
                targetFn = GlobalConsole.warn
                break
            case LogLevel.ERROR:
            case LogLevel.CRITICAL:
            case LogLevel.EMERGENCY:
            case LogLevel.ALERT:
                targetFn = GlobalConsole.error
                break
            default:
                targetFn = GlobalConsole.info
                break

        }
        targetFn(`${ll}@${logItem.context.toString()}: ${logItem.message}`, ...logItem.args);
    }
}

export default GenericLogOutput