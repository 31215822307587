import NavigationTree from "./NavigationTree";
import NavigationNodeInterface from "./NavigationNodeInterface";

export class LiveNavigationNode
{
    private tree: NavigationTree;
    private node: NavigationNodeInterface;
    private parentNodeId: string;
    constructor(tree:NavigationTree,
                parentNodeId:string,
                node: NavigationNodeInterface)
    {
        this.tree = tree;
        this.parentNodeId = parentNodeId;
        this.node = node;
    }

    /**
     * volat nejdriv na mount, ne na beforemount! Potrebujeme DOM
     */
    mount() {
        this.tree.addNode(this.node, this.parentNodeId)
        this.tree.fireUpdate()
    }

    unmount() {
        this.tree.removeNode(this.node.nodeId)
        this.tree.fireUpdate()
    }

    /**
     * @param nameTransId null for no change
     * @param translateParams null for no change
     */
    updateName(nameTransId: string | null, translateParams: object|null = null) {
        if (nameTransId !== null) {
            this.node.nameTransId = nameTransId
        }
        if (translateParams !== null) {
            this.node.translateParams = translateParams
        }
        this.tree.fireUpdate()
    }
}

export default LiveNavigationNode