import {defineComponent, onMounted} from 'vue'
import {SomeComponent} from "../types";
import DevLinkBuilder from "./DevLinkBuilder";

export const DevLinkProxyPage =
    (devLinkBuilder:DevLinkBuilder):SomeComponent =>
    {
        return defineComponent({
            name: 'RedirectAndClose',
            render() {
                return "Dev link open in progress..."
            },
            setup() {
                onMounted(() =>
                {
                    const urlParams = new URLSearchParams(window.location.search);
                    const targetPath = urlParams.get('file');
                    if (!targetPath) {
                        console.error('RedirectAndClose: missing target file');
                        return;
                    }
                    const targetLine = urlParams.get('line') || '0';

                    const targetUrl = devLinkBuilder.buildDevLink(targetPath, targetLine)
                    console.log('RedirectAndClose: opening:', targetUrl);
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', targetUrl, true);
                    xhr.onloadend = function (e) {
                        const statucCode = xhr.status;
                        if (statucCode !== 200) {
                            console.error('RedirectAndClose: failed to open:', targetUrl, statucCode, e);
                        }else {
                            window.close();
                        }
                    };
                    xhr.send()

                });

                return {}
            }
        })
    }
;

export default DevLinkProxyPage;