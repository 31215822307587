import {LogItemInterface} from './LogItemInterface';
import {LogLevel} from './LogLevel';
import {ContextInterface} from './ContextInterface';

export class LogItem implements LogItemInterface
{
    public readonly context: ContextInterface;
    public readonly timestamp: Date
    public readonly level: LogLevel
    public readonly message: string
    public readonly args: any[]
    public _stackRaw: string | undefined;

    constructor(
                level: LogLevel,
                message: string|unknown,
                args: any[],
                context: ContextInterface,
                timestamp: Date|null = null,
    ) {
        //if message is not string, put it as first arg
        if (typeof message !== 'string'){
            args.unshift(message)
            this.message = ''
        }else{
            this.message = message
        }
        this.timestamp = timestamp || new Date();
        this.context = context;
        this.level = level;
        this.args = args;
    }
}