import NavigationNode from './NavigationNode';
import NavigationNodeInterface from './NavigationNodeInterface';
import LiveNavigationNode from './LiveNavigationNode';

export class NavigationTree extends NavigationNode
{
    private listenersUpdates: any[] = []

    constructor(routeName = '',
                nameTransId: string|null = null,
                icon: string|null = null,
                sortIndex = 0,
                meta: object = {},
                nodeId: string|null = null,
                children: NavigationNodeInterface[] = [])
    {
        super(routeName, nameTransId, icon, sortIndex, null, meta, nodeId, children)
    }

    addNode(navigationNode: NavigationNodeInterface, parentNodeId:string|null = null)
    {
        let parent: NavigationNodeInterface
        if (parentNodeId) {
            const parentLookup = this.findNode(parentNodeId)
            if (!parentLookup) {
                throw new Error('Parent node not found: ' + parentNodeId)
            }
            parent = parentLookup
        }else{
            parent = this
        }

        parent.children.push(navigationNode)
        if (navigationNode.sortIndex === 0) {
            navigationNode.sortIndex = parent.children.length*100
        }else{
            //sort children by sortIndex
            parent.children.sort((a, b) => {
                return a.sortIndex - b.sortIndex
            })
        }
    }

    removeNode(nodeId:string) {
        const parent:NavigationNodeInterface|null = this.findParentNode(nodeId)
        if (!parent) {
            throw new Error('Parent node not found: ' + nodeId)
        }

        const index = parent.children.findIndex((node) => {
            return node.nodeId === nodeId
        })
        if (index === -1) {
            throw new Error('Node not found: ' + nodeId)
        }

        parent.children.splice(index, 1)
    }

    removeChildren(nodeId:string) {
        const parent = this.findNode(nodeId)
        if (!parent) {
            throw new Error('Parent node not found: ' + nodeId)
        }

        parent.children = []
    }

    public findNode(lookupNodeId: string, searchFrom: NavigationNodeInterface|null = null): NavigationNodeInterface|null
    {
        if (!searchFrom) {
            searchFrom = this
        }

        if (searchFrom.nodeId === lookupNodeId) {
            return searchFrom
        }
        if (!searchFrom.children){
            //FIXME error?
            return null
        }
        for (const child of searchFrom.children) {
            const found = this.findNode(lookupNodeId, child)
            if (found) {
                return found
            }
        }
        return null
    }

    public findParentNode(lookupNodeId: string, searchFrom: NavigationNodeInterface|null = null): NavigationNodeInterface|null
    {
        if (!searchFrom) {
            searchFrom = this
        }

        for (const child of searchFrom.children) {
            if (child.nodeId === lookupNodeId) {
                return searchFrom
            }
            const found = this.findParentNode(lookupNodeId, child)
            if (found) {
                return found
            }
        }
        return null
    }

    public findParents(nodeId: string, includeRoot = false, includeSelf = false): NavigationNodeInterface[]
    {
        const node = this.findNode(nodeId)
        if (!node) {
            throw new Error('Node not found: ' + nodeId)
        }
        const parents: NavigationNodeInterface[] = []
        if (includeSelf) {
            parents.push(node)
        }
        let parent = this.findParentNode(nodeId)
        while (parent) {
            if (!includeRoot && parent.nodeId === this.nodeId) {
                break
            }
            parents.push(parent)
            parent = this.findParentNode(parent.nodeId)
        }
        return parents
    }

    public fireUpdate(): void
    {
        for (const listener of this.listenersUpdates) {
            listener()
        }
    }

    subscribeUpdates(callback: (() => void) | any) {
        this.listenersUpdates.push(callback)
    }

    unsubscribeUpdates(callback: (() => void) | any) {
        const index = this.listenersUpdates.indexOf(callback)
        if (index !== -1) {
            this.listenersUpdates.splice(index, 1)
        }
    }

    clone() {
        return new NavigationTree(this.routeName,
            this.nameTransId,
            this.icon,
            this.sortIndex,
            this.meta,
            this.nodeId,
            this.children)
    }

    addLiveNode(parentNodeId:string, node: NavigationNodeInterface): LiveNavigationNode {
        return  new LiveNavigationNode(this, parentNodeId, node)
    }

    async lazyLoadNode(nodeId: string) {
        const node:NavigationNodeInterface|null = this.findNode(nodeId)
        if (!node) {
            throw new Error('Node not found: ' + nodeId)
        }
        if (!node.lazy) {
            throw new Error('Node is not lazy: ' + nodeId)
        }
        this.removeChildren(nodeId) //standardne tam stejne nic neni, behem HMR muze
        const newNodes = await node._lazyLoad()
        newNodes.forEach((newNode) => {
            //console.log("lazyLoadNode addNode " + newNode.nodeId)
            //dvoji pridani...poradi si s tim qtree?
            this.addNode(newNode, nodeId)
        })
        //console.log("lazyLoadNode update")
        //fireUpdate neni treba, ale asi je koser
        this.fireUpdate()
        return node.children
    }
}

export default NavigationTree