import {core} from '../../Core';
import {
    ILoginService, LoginServiceEventNamesEnum,
    type LoginServiceLoggedIn,
    type LoginServiceLoggedOut
} from './ILoginService';
import OAuth2ApiService from "./OAuth2ApiService";
import {EventEmitter} from "../../internals/EventEmitter";
import LoggerInterface from "../../logging/input/LoggerInterface";

export class OAuth2LoginService implements ILoginService {
    private apiService: OAuth2ApiService;
    private event:EventEmitter = new EventEmitter()
    private logger: LoggerInterface;

    constructor(apiService: OAuth2ApiService)
    {
        this.logger = core.log.getCoreLogger('OAuth2LoginService');
        this.apiService = apiService;
        this.apiService.onAuth(() => {
            this.logger.debug('onAuth')
            this.event.emit(LoginServiceEventNamesEnum.loggedIn, {})
        })
        this.apiService.onDeauth(() => {
            this.logger.debug('onDeauth')
            this.event.emit(LoginServiceEventNamesEnum.loggedOut, {})
        })
    }

    async login(credentials: { username: string, password: string }): Promise<void> {
        await this.apiService.doAuthIn(credentials);
        //this.event.emit(LoginServiceEventNamesEnum.loggedIn, {})
    }

    async logout(): Promise<void> {
        await this.apiService.doAuthOut();
    }

    public isAuthenticated(): boolean {
        return this.apiService.isAuthenticated();
    }

    public onLoggedIn(callback: LoginServiceLoggedIn) {
        this.event.register(LoginServiceEventNamesEnum.loggedIn, callback)
    }
    public unregisterOnLoggedIn(callback: LoginServiceLoggedIn) {
        this.event.unregister(LoginServiceEventNamesEnum.loggedIn, callback)
    }
    public onLoggedOut(callback: LoginServiceLoggedOut) {
        this.event.register(LoginServiceEventNamesEnum.loggedOut, callback)
    }
    public unregisterOnLoggedOut(callback: LoginServiceLoggedOut) {
        this.event.unregister(LoginServiceEventNamesEnum.loggedOut, callback)
    }
}

export default OAuth2LoginService;
