import {LoggerInterface} from "./LoggerInterface";
import {LogOutputInterface} from "../output/LogOutputInterface";
import {LogLevel} from "../LogLevel";
import {LogItem} from "../LogItem";
import {ContextInterface} from "../ContextInterface";
import {Context} from "../Context";

export class GenericLogger implements LoggerInterface
{
    private logOutput: LogOutputInterface
    private readonly context: ContextInterface
    private readonly logLevelLimit: LogLevel

    /**
     *
     * @param logOutput
     * @param context
     * @param logLevelLimit local log level limit, nothing will be logged from this logger if log level is higher than this
     */
    constructor(logOutput: LogOutputInterface,
                context: ContextInterface,
                logLevelLimit: LogLevel
    ) {
        this.logOutput = logOutput;
        this.context = context;
        this.logLevelLimit = logLevelLimit;
    }

    public log(logLevel:LogLevel, message: string, ...args: any[]): void
    {
        if (logLevel > this.logLevelLimit) {
            return;
        }

        this.logOutput.log(
            new LogItem(
                logLevel,
                message,
                args,
                this.context
            )
        );
    }

    debug(message: string, ...args: any[]): void {
        this.log(LogLevel.DEBUG, message, ...args);
    }

    error(message: string, ...args: any[]): void {
        this.log(LogLevel.ERROR, message, ...args);
    }

    info(message: string, ...args: any[]): void {
        this.log(LogLevel.INFO, message, ...args);
    }

    notice(message: string, ...args: any[]): void {
        this.log(LogLevel.NOTICE, message, ...args);
    }

    warn(message: string, ...args: any[]): void {
        this.log(LogLevel.WARNING, message, ...args);
    }

    public getSubLogger(contextName: string, logLevelLimit: LogLevel|null = null): LoggerInterface
    {
        const newContext = new Context(contextName, this.context)
        return new GenericLogger(this.logOutput, newContext, logLevelLimit ?? this.logLevelLimit)
    }

    public getContext(): ContextInterface
    {
        return this.context;
    }
}

export default GenericLogger;