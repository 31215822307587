import NavigationTree from "./NavigationTree";

type TreesByName = {
    [key: string]: NavigationTree;
};
export class NavigationManager
{
    private readonly defaultTreeId = 'default'
    private treesByIds: TreesByName = {};
    constructor()
    {
        this.createTree(this.defaultTreeId)
    }

    createTree(treeId: string): NavigationTree
    {
        if (this.treesByIds[treeId]) {
            throw new Error("Tree with id " + treeId + " already exists")
        }
        this.treesByIds[treeId] = new NavigationTree('')
        return this.treesByIds[treeId]
    }

    getTree(treeId: string): NavigationTree|null
    {
        if (!this.treesByIds[treeId]) {
            return null
        }
        return this.treesByIds[treeId];
    }

    getDefaultTree(): NavigationTree
    {
        const tree = this.getTree(this.defaultTreeId)
        if (!tree) {
            throw new Error("Default tree not found")
        }
        return tree
    }
}

export default NavigationManager