import en from './en';
import cs from './cs';

export default {
  'en-US': en,
  'cs-CZ': {
    ...cs,

    "nav": {
      "others": "Ostatní",
    },
  }
};
