export const LogLevel = {
    DEBUG: 8,
    INFO: 7,
    NOTICE: 6,
    WARNING: 5,
    ERROR: 4,
    CRITICAL: 3,
    ALERT: 2,
    EMERGENCY: 1
} as const;

export type LogLevel = typeof LogLevel[keyof typeof LogLevel];

export function getLogLevelName(logLevel: LogLevel): string {
    switch (logLevel) {
        case LogLevel.DEBUG:
            return "DEBUG";
        case LogLevel.INFO:
            return "INFO";
        case LogLevel.NOTICE:
            return "NOTICE";
        case LogLevel.WARNING:
            return "WARNING";
        case LogLevel.ERROR:
            return "ERROR";
        case LogLevel.CRITICAL:
            return "CRITICAL";
        case LogLevel.ALERT:
            return "ALERT";
        case LogLevel.EMERGENCY:
            return "EMERGENCY";
        default:
            return "UNKNOWN";
    }
}

export default {
    LogLevel,
    getLogLevelName
}