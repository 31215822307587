import {IApiService} from "./api/IApiService";
import {ILoginService} from "./api/ILoginService";
import {core} from "../Core";

export class ConnectManager
{
    private apiServices: { [id: string]: IApiService } = {};
    private loginServices: { [id: string]: ILoginService } = {};
    public constructor() {
    }

    public addApiService(apiService: IApiService, id:string = 'default'): void
    {
        this.apiServices[id] = apiService;
    }

    public getApiService(id: string): IApiService
    {
        return this.apiServices[id];
    }

    public getDefaultApiService(): IApiService
    {
        return this.getApiService('default');
    }

    public addLoginService(loginService: ILoginService, id:string = 'default'): void
    {
        this.loginServices[id] = loginService;
    }

    public getLoginService(id: string): ILoginService
    {
        return this.loginServices[id];
    }

    public getDefaultLoginService(): ILoginService
    {
        return this.getLoginService('default');
    }
}

export default ConnectManager;