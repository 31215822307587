import ConfigManager from "../config/ConfigManager";

export class DevLinkBuilder {
    private devSourceLinkTemplate: string;
    private sourceMap: { [key: string]: string } = {}
    constructor(conf:ConfigManager)
    {
        this.devSourceLinkTemplate = conf.env.getEnvVariableString(
            'DEV_SRC_URL', ////vite: must: viteConf.envPrefix = [ 'VITE_', 'VUE_', 'DEV_']
            "http://localhost:63342/api/file?file=%f&line=%l" //phpstorm native - but blank page will open
        )

        const mapFrom = conf.env.getEnvVariableString('DEV_SRC_MAP_FROM', '')
        const mapTo = conf.env.getEnvVariableString('DEV_SRC_MAP_TO', '')
        if (mapFrom) {
            const f = mapFrom.split(';')
            const t = mapTo.split(';')
            for (let i = 0; i < f.length; i++) {
                this.sourceMap[f[i]] = t[i]
            }
        }
    }

    public buildDevLink(targetPath:string, targetLine:string):string
    {
        return this.devSourceLinkTemplate.replace('%f', targetPath).replace('%l', targetLine);
    }

    public applySourceMapping(srcFilePath: string) {
        let srcCodePath = srcFilePath
        for (const [key, value] of Object.entries(this.sourceMap)) {
            if (srcCodePath.indexOf(key) === 0) {
                if (srcCodePath.indexOf("/@fs") === 0){
                    srcCodePath = srcCodePath.replace("/@fs", "")
                }
                srcCodePath = srcCodePath.replace(key, value)
                //GlobalConsole.log('path mapped', p, '->', srcCodePath)
            }

        }
        return srcCodePath
    }
}

export default DevLinkBuilder;