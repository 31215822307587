<template>
  <q-card
      v-if="visible"
  >
    <q-card
        ref="devPanelCard"
        class="floating-debug"
        :style="{ top: position.y + 'px', left: position.x + 'px' }"
    >
      <q-bar
          @mousedown="startDrag"
          dark

      >
        <label class="header">Debug Mode Enabled</label>
      </q-bar>
      <q-tabs
          v-model="tab"
          dark
      >
        <q-tab
            name="log"
            label="Logging"
        />
        <q-tab
            name="conf"
            label="Config"
        />
      </q-tabs>
      <q-tab-panels v-model="tab">
        <q-tab-panel name="log">
          <div>
            <h3>Logging</h3>
          </div>
        </q-tab-panel>
        <q-tab-panel name="conf">
          <div>
            <h3>Config</h3>
          </div>
        </q-tab-panel>
      </q-tab-panels>


    </q-card>
  </q-card>
</template>
<style scoped lang="scss">
.floating-debug {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
  text-wrap: nowrap;
}
.floating-debug .header{
  text-wrap: nowrap;
}
</style>
<script setup lang="ts">
import {nextTick, onMounted, onUnmounted, ref} from 'vue';
import {core, RunModes} from '../Core';

const devPanelCard = ref();
const tab = ref<string>('conf');
const logger = core.log.createLogger('DevPanel')
logger.info('DevPanel created')

//region Visibility
const visible = ref(true);
const show = () => {
  logger.debug('show')
  visible.value = true;
  nextTick(() => {
    //need time to render to get size
    setRightDown()
  })
};
const hide = () => {
  logger.debug('hide')
  visible.value = false;
};
const toggle = () => {
  if (visible.value) hide(); else show()
};
//endregion

//region Dragging & postioning
const dragStartRelativePosition = ref({ x: 0, y: 0 });
const isDragging = ref(false);
const position = ref({ x: 0, y: 0 });

const startDrag = (event) => {
  logger.info('startDrag')
  isDragging.value = true;
  dragStartRelativePosition.value = {
    x: event.clientX - position.value.x,
    y: event.clientY - position.value.y
  };
  window.addEventListener('mousemove', doDrag);
  window.addEventListener('mouseup', stopDrag);
  window.addEventListener('mouseleave', stopDrag);
};

const getDevPanelCardSize = () => {
  if (devPanelCard.value) {
    const el = devPanelCard.value.$el ?? devPanelCard.value;
    const rect = el.getBoundingClientRect();
    logger.info('getDevPanelCardSize', rect.width, rect.height)
    return {
      width: rect.width,
      height: rect.height
    };
  }
  logger.error('getDevPanelCardSize', devPanelCard.value)
  throw new Error('DevPanel card not ready');
};
const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
};

const doDrag = (event) => {
  if (isDragging.value) {
    let newX = event.clientX - dragStartRelativePosition.value.x;
    let newY = event.clientY - dragStartRelativePosition.value.y;

    const devPanelCardSize = getDevPanelCardSize();
    const winSize = getWindowSize();
    //udrz alespon 20px horni casti, kde je drag
    newX = Math.max(newX, 0 - devPanelCardSize.width + 20);
    newY = Math.max(newY, 0 - 20);
    newX = Math.min(newX, winSize.width - 20);
    newY = Math.min(newY, winSize.height - 20);

    position.value = { x: newX, y: newY };
  }
};

const stopDrag = () => {
  isDragging.value = false;
  window.removeEventListener('mousemove', doDrag);
  window.removeEventListener('mouseup', stopDrag);
  window.removeEventListener('mouseleave', stopDrag);
};

const setRightDown = () => {
  try {
    const devPanelCardSize = getDevPanelCardSize();
    const winSize = getWindowSize();
    position.value = {
      x: (winSize.width - devPanelCardSize.width - 5),
      y: (winSize.height - devPanelCardSize.height -5)
    };
  } catch (e) {
    logger.error('setRightDown', e)
  }
};
//endregion

//region Global shortcut
let keyActivator:number = 0
const handleGlobalShortcut = (event: KeyboardEvent) => {
  if (event.ctrlKey && event.altKey) {
    if (keyActivator == 0 && event.code === 'KeyF') {
      keyActivator = 1
      if (core.info.runMode == RunModes.DEVELOPMENT){
        toggle()
      }else {
        return
      }
    } else if (keyActivator == 1 && event.code === 'KeyE') {
      keyActivator = 2
      return
    } else if (keyActivator == 2 && event.code === 'KeyO') {
      toggle()
    }
  }
  keyActivator = 0
};
//endregion

onMounted(() => {
  window.addEventListener('keyup', handleGlobalShortcut);
  visible.value = false;
});

onUnmounted(() => {
  window.removeEventListener('keyup', handleGlobalShortcut);
});

</script>

