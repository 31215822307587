import {
    Router,
    type RouteRecordRaw,
    isNavigationFailure,
    NavigationFailureType,
    NavigationFailure,
    ErrorTypes,
    RouteLocation
} from 'vue-router'
import RouteDefinitionInterface from "./RouteDefinitionInterface";
import RoutesManagerInterface from "./RoutesManagerInterface";
import {RouteDefinition, RouteNodeDefinition} from "./RouteDefinition";
import {SomeComponent} from "../types";
import LoggerInterface from "../logging/input/LoggerInterface";

export default class RoutesManager implements RoutesManagerInterface
{
    private readonly router: Router
    private logger: LoggerInterface;

    constructor(vueRouter: Router, logger: LoggerInterface)
    {
    this.router = vueRouter;
    this.logger = logger
    this.hookRouter()
    }

    private getRouteAsString(route:RouteLocation): string
    {
        if (route.name){
            return (typeof route.name === 'symbol') ? route.name.toString() : route.name
        }
        return route.path
    }

    private hookRouter()
    {
        this.router.afterEach((to, from, failure) =>
        {
            const fromString = this.getRouteAsString(from)
            const toString = this.getRouteAsString(to)
            const msgBase = "Routing from " + fromString + " to " + toString
            if (!failure){
                this.logger.info(msgBase + " was successful")
            }else {
                if (isNavigationFailure(failure, NavigationFailureType.aborted)){
                    this.logger.warn(msgBase + " was aborted")
                }else if (isNavigationFailure(failure, NavigationFailureType.cancelled)){
                    this.logger.warn(msgBase + " was canceled")
                }else if (isNavigationFailure(failure, NavigationFailureType.duplicated)){
                    this.logger.debug(msgBase + " was duplicated")
                }else{
                    this.logger.error(msgBase + " failed, unknown reason")
                }
            }
        })
    }

    async pushRoute(routeName: string, params: any = {}, forceOnCancel: boolean = false)
    {
        const result = await this.router.push({name: routeName, params: params})
        if (isNavigationFailure(result, NavigationFailureType.cancelled)) {
            this.logger.info("Route " + routeName + " was canceled, forcing again")
            await this.router.push({name: routeName, params: params})
        }
        return result
    }

    getRouter(): Router
    {
        return this.router
    }

//region addRoute
    /**
     * Route defined by RouteDefinition
     * @param routeDef RouteDefinition
     */
    addRoute(routeDef: RouteDefinitionInterface): RouteDefinitionInterface
    {
        return this._addRoute(routeDef)
    }

    /**
     * shortcut: Route as node, using RouteNodeDefinition
     * @param routeName
     * @param path
     * @param nodeComponent
     * @param defaultComponent
     * @param meta
     * @param parentRouteName
     */
    addRouteNode(routeName: string,
             path: string,
             nodeComponent: SomeComponent,
             defaultComponent: SomeComponent,
             meta: any = null,
             parentRouteName: string = ''): RouteNodeDefinition
    {
        return this._addRoute(new RouteNodeDefinition(
            routeName,
            path,
            nodeComponent,
            defaultComponent,
            meta,
            parentRouteName)) as RouteNodeDefinition
    }

    /**
     * shortcut: Route as leaf, using RouteDefinition
     * @param routeName
     * @param path
     * @param component
     * @param meta
     * @param parentRouteName
     */
    addRouteLeaf(routeName: string,
                 path: string,
                 component: SomeComponent,
                 meta: any = null,
                 parentRouteName: string = ''): RouteDefinition
    {
        return this._addRoute(new RouteDefinition(
            routeName,
            path,
            component,
            meta,
            parentRouteName)) as RouteDefinition
    }
    private _addRoute(routeDef: RouteDefinitionInterface): RouteDefinitionInterface
    {
        let parentRouteCalc:string|null = routeDef.parentRouteName
        if (parentRouteCalc == ''){
            parentRouteCalc = null
        }else if (this.router.hasRoute(routeDef.parentRouteName + "--logic")){
            parentRouteCalc += "--logic"
        }
        switch (routeDef.components.length){
            case 0:
                throw new Error("RouteDefinition must have at least one component")
            case 1:
                this.logger.debug("Add route " + routeDef.path + " parent:" + routeDef.parentRouteName)
                this.realAddRoute(
                    parentRouteCalc,
                    {
                        name: routeDef.routeName,
                        path: routeDef.path,
                        props: true,
                        component: routeDef.components[0],
                        meta: routeDef.meta
                    }
                );
                break
            case 2:
                const nodeIsEmpty = routeDef.components[0] === null
                if (nodeIsEmpty || 1){
                    this.logger.debug("Add route " + routeDef.path + " parent:" + routeDef.parentRouteName)
                    this.realAddRoute(
                        parentRouteCalc,
                        {
                            name: routeDef.routeName + "--logic",
                            path: routeDef.path,
                            component: routeDef.components[0], //musi byt null, jinak neroutetuje na children
                            meta: routeDef.meta,
                            children: [
                                {
                                    name: routeDef.routeName, //tento cil musi byt nezmenen, aby se roura propojila s comp
                                    path: '',
                                    component: routeDef.components[1],
                                    meta: routeDef.meta
                                }
                            ]
                        }
                    );
                }else{
                    //tohle uz snad nebude potreba:
                    this.logger.debug("Add route " + routeDef.path + " parent:" + routeDef.parentRouteName)
                    this.realAddRoute(
                        parentRouteCalc,
                        {
                            name: routeDef.routeName ,
                            path: routeDef.path,
                            component: routeDef.components[0],
                            meta: routeDef.meta,
                        }
                    );
                    this.realAddRoute(
                        routeDef.routeName,
                        {
                            name: routeDef.routeName + '--default',
                            path: routeDef.path,
                            component: routeDef.components[1],
                            meta: routeDef.meta
                        }
                    );
                }
                break;
            default:
                throw new Error("RouteDefinition can have at most two components")
        }
        return routeDef
    }
//endregion

  private realAddRoute(parent: string|null, rawData: RouteRecordRaw)
  {
      if (parent !== null) {
          this.router.addRoute(parent, rawData)
      }else{
          this.router.addRoute(rawData)
      }
  }
}
