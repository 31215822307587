<template>
  <router-view />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
//widgets components
import { onBeforeMount, onMounted } from 'vue';
import { core, OAuth2ApiService, OAuth2LoginService } from 'feo-app-core';
import type { TConfigDataAdmin, TConfigDataApi } from 'quasar-ui-admin-base';


const logger = core.log.createLogger('App');


onBeforeMount(() =>
{

  const apiConfig = core.conf.getConfig<TConfigDataApi>();

  console.log('apiConfig', apiConfig);
  const oAuth2ApiService = new OAuth2ApiService(
    apiConfig.api.baseURL,
    apiConfig.api.oauth?.clientId ?? '1',
    apiConfig.api.oauth?.clientSecret ?? ''
  );

  core.connect.addApiService(oAuth2ApiService);
  core.connect.addLoginService(
    new OAuth2LoginService(oAuth2ApiService));


  logger.info('Admin app ready to serve');
})

onMounted(() => {
  logger.info('Mounted')

  //project settings, overwrite default values of plugin & boot stage:
  core.conf.addDefaults<TConfigDataAdmin>({
    admin: {
      logo: {
        src: "/logo-white.svg",
      },
    },
  })
})

</script>
