import LoggerInterface from "../LoggerInterface"
import {LogLevel} from "../../LogLevel"
import {Context} from "../../Context"
import GlobalConsole from "../../output/Global/GlobalConsole"

/**
 * Logs to console, always available.
 * This works as a temporary logger before the real logger is set up
 */
export class GlobalConsoleLogger implements LoggerInterface
{

    __constructor() {

    }
    public log(logLevel:LogLevel, message: string, ...args: any[]): void
    {
        switch (logLevel) {
            case LogLevel.ERROR:
            case LogLevel.CRITICAL:
            case LogLevel.EMERGENCY:
            case LogLevel.ALERT:    GlobalConsole.error(message, ...args); break
            case LogLevel.WARNING:  GlobalConsole.warn(message, ...args); break
            case LogLevel.NOTICE:   GlobalConsole.info(message, ...args); break
            case LogLevel.INFO:     GlobalConsole.log(message, ...args); break
            case LogLevel.DEBUG:    GlobalConsole.debug(message, ...args); break
        }
    }

    getContext(): Context {
        return new Context('global');
    }

    getSubLogger(contextName: string, logLevelLimit: LogLevel | null): LoggerInterface {
        throw new Error('Cannot create sublogger for GlobalConsole');
    }

    error(message: string, ...args: any[]): void {
        this.log(LogLevel.ERROR, message, ...args)
    }

    warn(message: string, ...args: any[]): void {
        this.log(LogLevel.WARNING, message, ...args)
    }

    notice(message: string, ...args: any[]): void {
        this.log(LogLevel.NOTICE, message, ...args)
    }

    info(message: string, ...args: any[]): void {
        this.log(LogLevel.INFO, message, ...args)
    }

    debug(message: string, ...args: any[]): void {
        this.log(LogLevel.DEBUG, message, ...args)
    }
}