
export class StackItem {
    srcUrl: URL;
    functionName: string;

    srcFilePath: string;
    srcFileLine: number;
    srcFileColumn: number;
    mapped: boolean = false;

    public constructor(url: URL) {
        this.srcUrl = url
        this.functionName = 'unknown'

        this.srcFilePath = ''
        this.srcFileLine = 0
        this.srcFileColumn = 0
    }


    public toString(): string {
        return `${this.functionName} (${this.srcFilePath}:${this.srcFileLine}:${this.srcFileColumn}) ${this.mapped ? 'mapped' : ''}`
    }
}