import {StackItem} from "./StackItem";
import GlobalConsole from "../output/Global/GlobalConsole";

export class StackParser {
    static parse(rawStack: string): StackItem[]
    {
        const stack:StackItem[] = [];
        const lines = rawStack.split('\n').slice(1);
        //GlobalConsole.log("Parsing stack: ", lines)
        lines.forEach((stackLine) => {
            if (!stackLine.trim()){
                return;
            }
            //general parsing " at <functionName> (url:line:column)"
            const match: RegExpMatchArray | null =
                    stackLine.match(/^\s*at\s+(async\s+)?(((?<fn>.+)\s+\((?<url>[^)]*)\))|(?<url2>[^()]+))$/);
            if (!match || !match.groups){
                GlobalConsole.error("Failed to parse: ", stackLine)
                return;
            }
            const matchMapped = match as unknown as {groups: {fn: string, url: string, url2: string}}

            const funcCall = matchMapped.groups.fn || null
            const srcUrl = matchMapped.groups.url || matchMapped.groups.url2
            if ( funcCall && (
                    funcCall?.match(/^Array\./)
                    || funcCall?.match(/^Object\./)
                    || funcCall?.match(/^Function\./)
                    || funcCall?.match(/^Promise\./)
                    || funcCall?.match(/^Proxy\./)
            )){
                //silent skip
                return;
            }
            //srcUrl containt line adn typically also column
            let urlObj: URL
            try {
                urlObj = new URL(srcUrl)
            }
            catch (e){
                //sometime thing we cannot parse for file like Array.each...
                GlobalConsole.log('"'+stackLine+'"', stackLine, stackLine.length)
                GlobalConsole.error("Failed to parse URL: ", stackLine, match)
                return;
            }

            const item = new StackItem(urlObj)
            item.functionName = funcCall ?? 'unknown'

            if (urlObj.search == ""){
                const matchLinesBoom = urlObj.pathname.match(/^(?<file>.*?):(?<line>\d+)(:(?<column>\d+))?$/)
                if (!matchLinesBoom || !matchLinesBoom.groups){
                    GlobalConsole.error("Failed to parse matchLines: ", urlObj.pathname)
                    return;
                }
                const matchLines = matchLinesBoom.groups as {file: string, line: string, column: string}

                item.srcFilePath = matchLines.file
                item.srcFileLine = parseInt(matchLines.line)
                item.srcFileColumn = parseInt(matchLines.column)
            }else{
                const matchLinesBoom = urlObj.search.match(/^.*?:(?<line>\d+)(:(?<column>\d+))?$/)
                if (!matchLinesBoom || !matchLinesBoom.groups){
                    GlobalConsole.error("Failed to parse matchLines: ", urlObj.search)
                    return;
                }
                const matchLines = matchLinesBoom.groups as {line: string, column: string}
                item.srcFilePath = urlObj.pathname
                item.srcFileLine = parseInt(matchLines.line)
                item.srcFileColumn = parseInt(matchLines.column)
            }

            if (!item.srcFilePath){
                GlobalConsole.error("Failed to parse URL: ", stackLine)
                return;
            }
            stack.push(item)
        })
        return stack
    }
}