
import ApiService from './ApiService';
import {ITokenStorage} from './ITokenStorage';
import TokenStorage from './TokenStorage';
import {IApiService} from "./IApiService";
import {AxiosError, AxiosInstance} from 'axios';
import {jwtDecode} from "jwt-decode";
import {EventEmitter, CoreEventListener} from "../../internals/EventEmitter";
import LoggerInterface from "../../logging/input/LoggerInterface";
import {core} from "../../Core"
import {LogLevel} from "../../logging/LogLevel";


export enum OauthServiceEventNamesEnum {
    authSuccess = 'auth',
    deauthEvent = 'deauth'
}
export type OAuthServiceAuthCallback = CoreEventListener<object>
export type OAuthServiceDeauthCallback = CoreEventListener<object>

export class OAuth2ApiService extends ApiService implements IApiService {
    private tokenStorage: ITokenStorage;
    private clientId: string;
    private clientPublicSecret: string;
    private scope: string;

    private servicesEndpointBase: string;
    private loginEndpoint: string = '/oauth/token';
    private refreshEndpoint: string;
    private logoutEndpoint: string;

    /**
     * Instance for API request has different url and management.
     */
    private apiServiceForAuthOps: ApiService;

    private event:EventEmitter = new EventEmitter()
    private logger: LoggerInterface;
    private tokenRecoveryInProgress: boolean = false;

    constructor(baseURL: string,
                clientId: string,
                clientPublicSecret: string,
                scope: string = '',

                servicesEndpointBase: string = '/api',
                loginEndpoint: string = '/oauth/token',
                refreshEndpoint: string = '/oauth/token',
                logoutEndpoint: string = '/oauth/tokens/{token}',


                tokenStorage: ITokenStorage = new TokenStorage(),
    ) {
        //for oauth2 handling current clientm internal:
        if (!baseURL) {
            throw new Error('BaseURL is required')
        }
        const apiBaseUrl = baseURL.replace(/\/$/, '') + servicesEndpointBase
        const authBaseUrl = baseURL
        super(apiBaseUrl);
        this.apiServiceForAuthOps = new ApiService(authBaseUrl)
        this.logger = core.log.getCoreLogger('OAuth2LoginService', LogLevel.DEBUG);

        this.clientId = clientId;
        this.clientPublicSecret = clientPublicSecret;
        this.scope = scope;

        this.servicesEndpointBase = servicesEndpointBase
        this.loginEndpoint = loginEndpoint
        this.refreshEndpoint = refreshEndpoint
        this.logoutEndpoint = logoutEndpoint

        this.tokenStorage = tokenStorage;

        this.installAxiosAuthBearer(this.axiosClient);
        this.installAxiosAuthBearer(this.apiServiceForAuthOps.getAxiosInstance());
        this.installAxios401hook(this.axiosClient);
    }

    private installAxiosAuthBearer(axios:AxiosInstance) {
        axios.interceptors.request.use(config => {
            const token = this.tokenStorage.getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });
    }

    private installAxios401hook(axios:AxiosInstance) {
        axios.interceptors.response.use(response => {
            return response;
        }, async error => {
            this.logger.debug("Catched error", error)
            if (error.response
                && error.response.status == 401
            ) {
                return await this.handle401Error(error);
            }
            return Promise.reject(error)
        });
    }

    private async handle401Error (error: any) {
        const originalRequest = error.config;
        this.logger.debug("handling 401 hook", error)

        if (!this.isAuthenticated()){
            this.logger.debug("Not authed at moment, 401 expectable")
            return Promise.reject(error);
        }
        try {
            const ai = this.getAxiosInstance()
            if (!this.tokenRecoveryInProgress) {
                this.tokenRecoveryInProgress = true
                this.logger.debug("Lets try refresh the token")
                await this.refreshToken()
                this.logger.debug("Token refresh success")

                this.tokenRecoveryInProgress = false
                return ai(originalRequest)
            }else{
                this.logger.debug("Token refresh already in progress")
                await this.waitForTokenRecoveryToComplete()
                if (this.isAuthenticated()) {
                    return ai(originalRequest)
                }else{
                    this.logger.error("Token refresh wait not successfull")
                    return Promise.reject(error)
                }
            }
        }
        catch (e){
            this.tokenRecoveryInProgress = false
            this.logger.error("Refresh attempt failed", e)
            throw e
        }
    };

    private waitForTokenRecoveryToComplete(): Promise<void> {
        return new Promise((resolve, reject) => {
            const checkIntervalMs = 100; // Check every 100 milliseconds
            const timeoutMs = 5000; // Set a timeout of 5 seconds
            const startTime = Date.now();

            const intervalId = setInterval(() => {
                if (!this.tokenRecoveryInProgress) {
                    clearInterval(intervalId);
                    resolve();
                } else if (Date.now() - startTime > timeoutMs) {
                    clearInterval(intervalId);
                    reject(new Error('Timeout waiting for token recovery to complete'));
                }
            }, checkIntervalMs);
        });
    }

    /**
     * Perform login with given credentials, store token in local storage, and set it to axios client
     * Return without error if login is successful
     * @param credentials
     */
    public async doAuthIn(credentials: { username: string, password: string }): Promise<void> {
        try {
            this.logger.debug("Auth attempt starting...")
            const response = await this.apiServiceForAuthOps.getAxiosInstance()
                .post(this.loginEndpoint, {
                    grant_type: 'password',
                    client_id: this.clientId,
                    client_secret: this.clientPublicSecret,
                    scope: this.scope,
                    username: credentials.username,
                    password: credentials.password,
            })

            const { access_token, refresh_token, expires_in, token_type } = response.data;
            if (access_token === undefined) {
                throw new Error('No access token in response');
            }
            const expiresAt = Date.now() + expires_in * 1000;
            this.logger.debug("Auth reply successfull")
            this.tokenStorage.setToken(access_token, refresh_token, expiresAt);
        } catch (error:unknown) {
            if (error instanceof AxiosError) {
                if (!error.response) {
                    this.logger.error('Login failed:', error);
                    throw error;
                }
                switch (error.response.status) {
                    case 401:
                        throw new Error('Invalid credentials');
                    case 404:
                        throw new Error('User not found or endpoint not found?');
                    default:
                        this.logger.error('Login failed:', error.response.data);
                        throw error;
                }
            }else{
                this.logger.error('Login failed:', error);
                throw error;
            }
        }
    }

    async refreshToken(): Promise<string> {
        try {
            const refreshToken = this.tokenStorage.getRefreshToken()
            if (!refreshToken){
                throw new Error('No token')
            }
            const response = await this.apiServiceForAuthOps.getAxiosInstance()
                .post(this.refreshEndpoint, {
                    grant_type: 'refresh_token',
                    refresh_token: refreshToken,
                    client_id: this.clientId,
                    client_secret: this.clientPublicSecret,
                    scope: this.scope
            });
            const { access_token, refresh_token, expires_in } = response.data;
            const expiresAt = Date.now() + expires_in * 1000;
            this.tokenStorage.setToken(access_token, refresh_token, expiresAt);
            return access_token;
        } catch (error) {
            this.logger.error('Refresh token failed:', error);
            this.tokenStorage.clear();
            this.event.emit(OauthServiceEventNamesEnum.deauthEvent, {})
            throw error;
        }
    }

    getToken(): string | null {
        return this.tokenStorage.getToken();
    }

    async doAuthOut(): Promise<void>
    {
        if (this.tokenStorage.getToken())
        {
            this.logger.debug("Token delete attempt starting...")
            try {
                const t = this.tokenStorage.getToken()
                if (!t){
                    throw new Error('No token')
                }
                const tokenDecoded = jwtDecode(t);
                if (tokenDecoded && tokenDecoded.jti) {
                    //await this.apiServiceForAuthOps.getAxiosInstance().get('sanctum/csrf-cookie')
                    const url = this.logoutEndpoint.replace('{token}', tokenDecoded.jti)
                    await this.apiServiceForAuthOps.getAxiosInstance().delete(url)
                }
                this.logger.debug("Token delete successfull")
            } catch (e) {
                this.logger.warn("Failed to delete token", e)
            }
        }

        this.tokenStorage.clear();
        this.event.emit(OauthServiceEventNamesEnum.deauthEvent, {})
    }

    isAuthenticated(): boolean {
        const token = this.tokenStorage.getToken()
        return !!token && token.length > 32; //length to filter nonsense values
    }

    public onAuth(callback: OAuthServiceAuthCallback) {
        this.event.register(OauthServiceEventNamesEnum.authSuccess, callback)
    }

    public onDeauth(callback: OAuthServiceDeauthCallback) {
        this.event.register(OauthServiceEventNamesEnum.deauthEvent, callback)
    }
}

export default OAuth2ApiService;
