import {StackItem} from "./StackItem";
import {MapCache} from "./MapCache";
import GlobalConsole from "../output/Global/GlobalConsole";

export class StackSourceMapper
{
    private static cache: MapCache = new MapCache();

    static replaceSuffix(path: string, toSuffix = 'map'): string
    {
        const lastDot = path.lastIndexOf('.');
        if (lastDot !== -1)
        {
            return path.substring(0, lastDot) + '.' + toSuffix;
        }
        //fallback, never used
        return path + '.' + toSuffix;
    }

    static getSuffix(path: string): string
    {
        const lastDot = path.lastIndexOf('.');
        if (lastDot !== -1)
        {
            return path.substring(lastDot + 1);
        }
        return '';
    }

    static async mapStack(stack:StackItem[]):Promise<StackItem[]>
    {
        //known to not have map files, avoid 404 error:
        const blacklistSourceFiles = [
            '.quasar/app.js', //quasar app main file dev mode
            '\/vue-plugin\.js$', //vue plugin main file
            '\/src\/boot\/.*\.js$', //boot files
            '\/\.quasar\/client-entry\.js$', //quasar client entry*/
            'chrome-extension:\/\/' //chrome extensions

        ]
        const mappedStack:StackItem[] = [];
        for (const item of stack){
            try {
                //GlobalConsole.debug('Mapping stack item', item.srcUrl);
                let isBlacklisted = false;
                isBlacklisted = blacklistSourceFiles.some((blacklistSourceFile) => {
                    return item.srcFilePath.match(blacklistSourceFile)
                        || item.srcUrl.origin.match(blacklistSourceFile)
                })
                if (isBlacklisted){
                    mappedStack.push(item);
                    continue;
                }


                const currentSuffix = this.getSuffix(item.srcFilePath);
                //console.log('currentSuffix', currentSuffix);
                let mapUrl
                switch (currentSuffix) {
                    case 'vue':
                        mapUrl = this.replaceSuffix(item.srcUrl.pathname, 'vue.map');
                        break;
                    default:
                        //mapUrl = this.replaceSuffix(item.srcUrl.pathname);
                        mapUrl = item.srcFilePath + '.map';
                }
                mapUrl = item.srcUrl.origin + mapUrl
                //console.log('mapUrl', mapUrl);

                const consumer = await this.cache.get(mapUrl);
                const pos = consumer.originalPositionFor({
                    line: item.srcFileLine,
                    column: item.srcFileColumn
                });
                if (pos.source === null) {
                    //console.error('Failed to map position', item, pos);
                    mappedStack.push(item); // Pokud source map není dostupná, použije původní řádek
                    continue;
                }
                let sourcePath = pos.source

                //if is relatieve fix it
                if (sourcePath.match(/^\./)){
                    const dirPath = item.srcUrl.pathname.substring(0, item.srcUrl.pathname.lastIndexOf('/'))
                    sourcePath =  StackSourceMapper.resolvePath(dirPath + '/' + sourcePath)

                }
                //GlobalConsole.debug('Mapped stack item', pos.source, sourcePath);
                const mappedItem = new StackItem(
                    item.srcUrl
                )
                mappedItem.functionName = item.functionName ?? 'unknown'
                mappedItem.srcFilePath = sourcePath
                mappedItem.srcFileLine = pos.line ?? item.srcFileLine ?? 0
                mappedItem.srcFileColumn = pos.column ?? item.srcFileColumn ?? 0
                mappedItem.mapped = true
                mappedStack.push(mappedItem);
            } catch (error) {
                //GlobalConsole.error('Failed to map stack item', error, item.srcFilePath);
                //console.error('Failed to map stack item', error, item.srcFilePath);
                mappedStack.push(item); // Pokud source map není dostupná, použije původní řádek
            }
        }
        return mappedStack
    }

    static resolvePath(path: string): string {
        const stack = [];
        const parts = path.split('/');
        for (let i = 0; i < parts.length; i++) {
            if (parts[i] === '.' || parts[i] === '') continue;
            if (parts[i] === '..') {
                stack.pop();
            } else {
                stack.push(parts[i]);
            }
        }
        return stack.join('/');
    }

    static invalidateCache(filePath: string)
    {
        this.cache.invalidate(filePath);
    }
}