import { RouteRecordRaw } from 'vue-router';
import ErrorNotFound from 'pages/ErrorNotFound.vue';

const routes: RouteRecordRaw[] = [
/*
  {
    path: '/others',
    component: MainLayout,
    children: [

    ],
    meta: {
      isPublic: true,
      isForSidebar: true,
      icon: 'fa fa-cog',
      translate: 'nav.others'
    }
  },*/

  // Always leave this as last one,
  // but you can also remove it
  {
    name: 'error-404',
    path: '/:catchAll(.*)*',
    component: ErrorNotFound,
    meta: {}
  },
];

export default routes;
