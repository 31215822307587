import {TConfigDataNode} from "./types";

export class ConfigLoader {


    static async loadJsonExternal(url: string):Promise<TConfigDataNode> {
        const response = await fetch(url)
        //await new Promise(resolve => setTimeout(resolve, 10000))
        if (!response.ok) {
            throw new Error(`Failed to load ${url}`)
        }
        return response.json()
    }

}