import {EnvVars} from "../types";
import {ConfigEnvReader} from "./ConfigEnvReader";
import {TConfigData, TConfigDataNode} from "./types";
import {merge} from 'lodash-es'
import {ConfigLoader} from "./ConfigLoader";
import LoggerInterface from "../logging/input/LoggerInterface";
import {GlobalConsoleLogger} from "../logging/input/Global/GlobalConsoleLogger";

export class ConfigManager
{
    private readonly envReader: ConfigEnvReader
    private configDataLocal: TConfigDataNode = {};
    private configDataGlobal: TConfigDataNode = {}
    private logger: LoggerInterface;
    constructor(envs: EnvVars)
    {
        this.envReader = new ConfigEnvReader(envs)
        this.logger = new GlobalConsoleLogger()
    }

    public setLogger(logger: LoggerInterface)
    {
        this.logger = logger
    }

    async _loadConfigJson(envFileAppPath:string): Promise<TConfigDataNode>{
        if (this.envReader === null) {
            throw new Error('ConfigManager not initialized')
        }
        const configFilePath = this.envReader.getEnvPublicPathBase() + '/' + envFileAppPath
        const url = window.location.origin + configFilePath
        return await ConfigLoader.loadJsonExternal(url) as TConfigDataNode
    }

    /**
     * Part of init, for call with awaited resources
     * @internal
     * @param data
     */
    _setLocalConfig(data: TConfigDataNode)
    {
        if (data && data['env']){
            this.logger.debug('ConfigManager setLocalConfig', data)
            const localEnv:EnvVars = data['env'] as EnvVars
            Object.keys(localEnv).forEach((key) => {
                this.envReader.setEnvVariable(key, localEnv[key])
            })
            //unset data.env
            delete data.env
        }
        this.configDataLocal = data
        this.logger.debug('ConfigManager setLocalConfig', data, this.envReader)
    }

    addDefaults<T extends TConfigData>(defaults: T)
    {
        merge(this.configDataGlobal, defaults)
        this.logger.debug('ConfigManager addDefaults', defaults, this.configDataGlobal)
        //deepmerge defaults with existing configData
    }

    get env(): ConfigEnvReader
    {
        if (this.envReader === null) {
            throw new Error('ConfigManager not initialized')
        }
        return this.envReader
    }

    get(path:string, defaultValue: TConfigData = undefined): TConfigData
    {
        return this.lookupConfigData(path,
            this.configDataLocal,
            this.configDataGlobal,
            defaultValue)
    }
    private lookupConfigData(path: string,
                             dataLocal: TConfigData,
                             dataGlobal: TConfigData,
                             defaultValue: TConfigData = undefined): TConfigData
    {
        const boom = path.split('.')
        if (boom.length === 0) { //should not happen, but just in case
            return defaultValue
        }
        const key = boom[0]
        if (key === '*') {
            return merge(dataGlobal, dataLocal)
        }
        if (boom.length > 1) {
            return this.lookupConfigData(boom.slice(1).join('.'),
                (dataLocal && typeof dataLocal === 'object') ? dataLocal[key] : undefined,
                (dataGlobal && typeof dataGlobal === 'object') ? dataGlobal[key] : undefined,
                defaultValue)
        }
        if (dataLocal && typeof dataLocal === 'object' && dataLocal[key] !== undefined) {
            return dataLocal[key]
        }
        if (dataGlobal && typeof dataGlobal === 'object' && dataGlobal[key] !== undefined) {
            return dataGlobal[key]
        }
        return defaultValue
    }

    getConfig<T extends TConfigData>(defaultValue: T|undefined = undefined): T {
        return this.get('*', defaultValue) as T;
    }
}

export default ConfigManager;