import RouteDefinitionInterface from "./RouteDefinitionInterface";

export class RouteDefinition implements RouteDefinitionInterface
{
    public readonly routeName: string;
    public readonly parentRouteName: string = '';
    public readonly path: string;
    public readonly components: any[];
    public readonly meta: any;

    constructor(routeName: string,
                path: string,
                component: any,
                meta: any = null,
                parentRouteName: string = '')
  {
      this.routeName = routeName;
      this.path = path;
        this.parentRouteName = parentRouteName;

      if (component instanceof Array) {
          this.components = component;
      }else{
      this.components = [component];
      }
      this.meta = meta;
  }

  createSubRoute(name: string,
                 path: string,
                 component: any,
                 meta: any = null): RouteDefinitionInterface
  {
    return new RouteDefinition(
        name,
        this.interpretSubPath(path),
        component,
        meta,
        this.routeName
    );
  }

  createSubNodeRoute(name: string,
                    path: string,
                    nodeComponent: any,
                    defaultComponent: any,
                    meta: any = null): RouteDefinitionInterface
    {
        return new RouteNodeDefinition(
            name,
            this.interpretSubPath(path),
            nodeComponent,
            defaultComponent,
            meta,
            this.routeName
        );
    }

    getComponents(): any[] {
        return this.components;
    }

    getMeta(): any {
        return this.meta;
    }

    getPath(): string {
        return this.path;
    }

    getRouteName(): string {
        return this.routeName;
    }

    private interpretSubPath(path: string): string {
        if (path[0] === '/') {
            return path;
        }
        if (this.path.slice(-1) === '/') {
            return this.path + path;
        }else{
            return this.path + '/' + path;
        }
    }

}

export class RouteNodeDefinition extends RouteDefinition implements RouteDefinitionInterface
{
    constructor(routeName: string,
                path: string,
                nodeComponent: any,
                defaultComponent: any,
                meta: any = null,
                parentRouteName: string = '')
    {
        const components = [nodeComponent, defaultComponent];
        super(routeName, path, components, meta, parentRouteName);
    }

}

export default {
    RouteDefinition,
    RouteNodeDefinition
}
