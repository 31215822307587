import { i18nLocalDateTimeFormatsSet } from "../types";

/**
 * This is customization of i18n defaults, changes here are overrides!
 */
export const datetimeFormatsI18n:i18nLocalDateTimeFormatsSet =
//export const datetimeFormatsI18n =
{
    'cs-CZ': {
        short: {
            //year: 'numeric', month: 'short', day: 'numeric',
        },
        long: {
            //year: 'numeric', month: 'short', day: 'numeric',
            //    weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'en-US': {
        short: {
            //year: 'numeric', month: 'numeric', day: 'numeric' //m/d/Y
            //year: '2-digit', month: 'numeric', day: 'numeric' //m/d/Y
        },
        long: {
            //year: 'numeric', month: 'short', day: 'numeric',
            //    weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
}