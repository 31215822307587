// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CoreEventListener<T> {
    (data: T): void;
}

interface EventMap {
    [event: string]: Array<CoreEventListener<any>>;
}

export class EventEmitter {
    private eventMap: EventMap = {};

    register<T>(event: string, listener: CoreEventListener<T>): void {
        if (!this.eventMap[event]) {
            this.eventMap[event] = [];
        }
        this.eventMap[event].push(listener);
    }

    unregister<T>(event: string, listener: CoreEventListener<T>): void {
        if (!this.eventMap[event]) return;
        this.eventMap[event] = this.eventMap[event].filter(l => l !== listener);
    }

    emit<T>(event: string, data: T): void {
        if (!this.eventMap[event]) return;
        this.eventMap[event].forEach(listener=> (listener as CoreEventListener<T>)(data));
    }
}
