import {SourceMapConsumer} from "source-map-js";
import GlobalConsole from "../output/Global/GlobalConsole";

export class MapCache
{
    private readonly loadingQueue: {[key: string]: Promise<SourceMapConsumer>} = {};
    private readonly cache: {[key: string]: SourceMapConsumer} = {};

    constructor() {
        this.loadingQueue = {}
        this.cache = {}
    }

    public async get(url: string): Promise<SourceMapConsumer>
    {
        //GlobalConsole.debug('MapCache.get', Object.keys(this.cache))
        if (this.cache[url]){
            return this.cache[url]
        }
        if (this.loadingQueue[url] !== undefined){
            return await this.loadingQueue[url]
        }

        const loadPromise = this.loadUrl(url)
        const mapPromise= loadPromise.then((mapContent) => {
            return new SourceMapConsumer(mapContent as any);
        })
        this.loadingQueue[url] = mapPromise
        //wait for map to load:
        const consumer = await mapPromise
        //GlobalConsole.debug('MapCache.get', url, consumer)
        this.cache[url] = consumer
        delete this.loadingQueue[url]
        return consumer
    }

    private async loadUrl(url: string): Promise<string>
    {
        const response = await fetch(url)
        if (!response.ok) {
            throw new Error('Failed to fetch source map: ' + url);
        }
        return await response.text()
    }

    invalidate(filePath: string)
    {
        for (const [key, value] of Object.entries(this.cache)) {
            if (key.indexOf(filePath) !== -1) {
                GlobalConsole.debug('[core] invalidating: ' + key)
                delete this.cache[key]
            }
        }
    }
}