import { route } from 'quasar/wrappers';
import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router';

import routes from './routes';
import { earlyBoot } from '../boot/core';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(async function(/* { store, ssrContext } */) {

  let env = import.meta.env ?? process.env;

  const createHistory = env.SERVER
    ? createMemoryHistory
    : (env.VUE_ROUTER_MODE === 'hash'
        ? createWebHashHistory
        : createWebHistory
    )
  ;

  const router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes: [...routes],

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(env.VUE_ROUTER_BASE),
  });

  await earlyBoot(env, router)

  /*
  const routeAdmin = rm.addRoute(new RouteNodeDefinition(
    'admin-main',
    '/',
    MainLayout,
    IndexPage,
  ))

  rm.addRoute(routeAdmin.createSubRoute(
    'admin-dashboard',
    'dashboard',
    IndexPage,
  ))
  rm.addRoute(routeAdmin.createSubRoute(
    'admin-styleguide',
    'styleguide',
    StyleGuide,
  ))*/


  return router;
});
