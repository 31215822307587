
export const pluralizationRules =
{
    'cs-CZ': function(choice:number, choicesLength:number)
    {
        switch (choicesLength) {
            case 1: // jedna forma
                return 0;
            case 2: // dvě formy, jednotné a množné
                return choice === 1 ? 0 : 1;
            case 3: // tři formy, jednotné, dvojné a množné
                return choice === 1 ? 0 : choice >= 2 && choice <= 4 ? 1 : 2;
            default:
            case 4: // čtyři formy: 0, jednotné, dvojné a množné
                return choice === 0 ? 0
                            : choice === 1 ? 1
                                : choice >= 2 && choice <= 4 ? 2 : 3;

        }
    },
    'ru-RU': function(choice:number, choicesLength:number) {
        // this === VueI18n instance, so the locale property also exists here

        if (choice === 0) {
            return 0;
        }

        const teen = choice > 10 && choice < 20;
        const endsWithOne = choice % 10 === 1;

        if (choicesLength < 4) {
            return (!teen && endsWithOne) ? 1 : 2;
        }
        if (!teen && endsWithOne) {
            return 1;
        }
        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
        }

        return (choicesLength < 4) ? 2 : 3;
    }
}

export default pluralizationRules;