import { boot } from 'quasar/wrappers';

import messagesApp from 'src/i18n';

import { core, CoreSetup, OAuth2ApiService, OAuth2LoginService } from 'feo-app-core';
import { Router } from 'vue-router';

import { createPinia } from 'pinia'
const pinia = createPinia()

export const earlyBoot = async (env: any, router: Router) => {
  await CoreSetup.boot(env, router, 'config-local.json')
}

export default boot(({ app }) =>
{
  const logger= core.log.getCoreLogger('AppBoot')
  logger.debug('AppBoot')
  CoreSetup.wireApp(app)
  app.use(pinia)

  CoreSetup.initLocales(
    'cs-CZ',
    [ 'cs-CZ', 'en-US' ],
    messagesApp,
    'en-US'
  )



  //CoreSetup.setLogLevel('debug')
  //CoreSetup.setRunMode('development')
});
