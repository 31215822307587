import {EnvVars, TRunMode} from "./types";

export class AppInfo
{
    constructor(
        //defines app mode: any string, can influence app behavior, example: dev, prod, test, beta, rc
        public readonly appMode: string,
        //defines consumer of app: dev, prod, test
        public readonly runMode: TRunMode,
    ) {
    }
}

export default AppInfo;