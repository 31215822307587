// services/TokenStorage.ts
import { ITokenStorage } from './ITokenStorage';

class TokenStorage implements ITokenStorage {
    getToken(): string | null {
        return localStorage.getItem('token');
    }

    getRefreshToken(): string | null {
        return localStorage.getItem('refreshToken');
    }

    getExpiresAt(): number | null {
        return parseInt(localStorage.getItem('expiresAt') || '0');
    }

    setToken(token: string, refreshToken: string, expiresAt: number): void {
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('expiresAt', expiresAt.toString());
    }

    clear(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiresAt');
    }
}

export default TokenStorage;
