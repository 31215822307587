import {LangPacksInfo, LocaleLang, LocalePackId, LocaleLangTag} from '../types';
import langPacksIndex from 'quasar/lang/index.json'
import {Lang, QuasarLanguage} from 'quasar';
import LoggerInterface from "../../logging/input/LoggerInterface";

export class QuasarLocalizationManager
{
    private languagePacksIndex: LangPacksInfo[];
    private currentLangPack: QuasarLanguage|null = null
    private logger: LoggerInterface;
    constructor(
        activeLocaleFull: LocaleLangTag,
        logger: LoggerInterface
    ) {
        this.logger = logger.getSubLogger('quasar', null)
        this.languagePacksIndex = this.loadLangPacksIndex()
    }

    /**
     * Loads quasar language packs index to out internal structure.
     * Its generally same atlest for now in two main keys: isoName, nativeName
     * @private
     */
    private loadLangPacksIndex(): LangPacksInfo[]
    {
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore quasar not counting with index typing?
        const indexQuasar = langPacksIndex as QuasarLanguage[] //wrong type in d.ts for index?
        const index: LangPacksInfo[] = []
        for(const idx in indexQuasar){
            index.push({
                isoName: indexQuasar[idx].isoName,
                nativeName: indexQuasar[idx].nativeName
            })
        }
        return index
    }

    /**
     * Converts full locale string to language pack id by looking up the index.
     * @param localeFull
     * @private
     */
    private locale2LanguagePackId(localeFull: LocaleLangTag): LocalePackId
    {
        //test for long variant:
        if (this.languagePacksIndex.find((langPack: LangPacksInfo) => langPack.isoName === localeFull)){
            return localeFull
        }
        //test for short variant:
        const boom = localeFull.split('-')
        const localeLang:LocaleLang = boom[0]
        if (this.languagePacksIndex.find((langPack: LangPacksInfo) => langPack.isoName === localeLang)){
            return localeLang
        }
        throw new Error('Language pack not found for locale: ' + localeFull)
    }

    private async getLanguagePack(localeFull: LocaleLangTag): Promise<QuasarLanguage>{
        const langPackId = this.locale2LanguagePackId(localeFull)
        //dynamic imports - requires rollup-plugin-dynamic-import-vars
        const moduleLoad = import(`../../../node_modules/quasar/lang/${langPackId}.js`)

        return moduleLoad.then((module: {default: QuasarLanguage}) => {
            const langPack = module.default
            this.logger.debug('Loaded language pack: ', langPack.isoName, langPack)
            return langPack
        }).catch((err) => {
            throw new Error('Failed to load language pack: ' + langPackId + ' ' + err)
        })
    }

    public async setLocale(localeFull: LocaleLangTag): Promise<void>
    {
        const langPackId = this.locale2LanguagePackId(localeFull)
        const langPack = await this.getLanguagePack(langPackId)
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore Too complex or Quasar typing bug? diff on label
        Lang.set(langPack)
        this.currentLangPack = langPack
        this.logger.debug('Set quasar language pack: ', langPack.isoName, langPack)
    }

    public get(): QuasarLanguage
    {
        if (!this.currentLangPack) {
            throw new Error('Locale not set')
        }
        return this.currentLangPack
    }
}

export default QuasarLocalizationManager;