import {i18nLocaleMessagesLanguagesSet, types} from './types';
import {createI18n, I18n} from 'vue-i18n';
import {datetimeFormatsI18n} from './data/DatetimeFormatsI18n';
import {numberFormatsI18n} from './data/NumberFormatsI18n';
import {LocaleLangTag} from '../types';
import {Composer, ComposerOptions} from "vue-i18n";
import pluralizationRules from "./data/PluralizationRules";
import LoggerInterface from "../../logging/input/LoggerInterface";

//export declare type MessageCompiler<Message = string, MessageSource = string | ResourceNode> = (message: MessageSource, context: MessageCompilerContext) => MessageFunction<Message>;


export class I18nManager {
    private readonly i18n:I18n
    private logger: LoggerInterface;

    constructor(activeLocaleFull: LocaleLangTag,
                fallbackLocaleFull: LocaleLangTag,
                messages: i18nLocaleMessagesLanguagesSet,
                logger: LoggerInterface
    )
    {
        this.logger = logger.getSubLogger('i18n', null)
        /*console.log('Setting up i18n for locale: ',
        activeLocaleFull, fallbackLocaleFull,
        messages, datetimeFormatsI18n)*/
        this.i18n = createI18n({
            locale: activeLocaleFull,
            fallbackLocale: fallbackLocaleFull,
            legacy: false,
            messages: messages,
            datetimeFormats: datetimeFormatsI18n,
            numberFormats: numberFormatsI18n,
            pluralRules: pluralizationRules, //existuje i pluralizationRules, ale funguje tento
            messageResolver: this.messageResolver,
            //messageCompiler: this.messageCompiler
        });
    }

    public messageResolver(obj: any, path: string): any
    {
        //console.log('messageResolver', path)
        //Note: u pluralizace nedostava hodnotu
        //parse modificators:
        const modificatorsSupported = [ ':', '^' ]
        const modificators = []
        while (path.length>0 && modificatorsSupported.includes(path[0]))
        {
            modificators.push(path[0])
            path = path.slice(1)
        }

        //map modificators to actions:
        let pass = false
        let upperFirst = false
        modificators.forEach(modificator => {
            switch (modificator) {
                case ':': //pass modificator - do not resolve
                    pass = true
                    break
                case '^': //upper case first - for sentence/caption start
                    upperFirst = true
                    break
            }
        })

        let output:string
        if (!pass) {
            //general path parsing:
            const boom = path.split('.')
            while (boom.length > 0) {
                const key = boom.shift()
                if (key == null) {
                    return null
                }
                if (obj[key] == null) {
                    return null
                }
                obj = obj[key]
            }
            output = obj
        }else{
            output = path
        }

        if (upperFirst) {
            output = output.charAt(0).toUpperCase() + output.slice(1)
        }

        return output
    }

    public messageCompiler(message: string, context: any): string
    {
        this.logger.debug('messageCompiler', message, context)
        return message
    }

    public async setLocale(localeFull: LocaleLangTag): Promise<void>
    {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore locale is ComputedRefImpl
        this.getI18nInstance().locale.value = localeFull
    }

    getI18n(): I18n
    {
        return this.i18n
    }

    getI18nInstance(): Composer
    {
        return this.i18n.global as Composer
    }

    appendMessages(locale: LocaleLangTag, messages: types) {
        this.getI18nInstance().mergeLocaleMessage(locale, messages)
    }

    t(path: string, context: any) {
        return this.getI18nInstance().t(path, context)
    }
}