
const feoSymbolMini_darkBg = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjUwIDUwIDUyMyA1NDMiPjxwYXRoIGQ9Ik01MTggMzI5YzMtMTcgMTEtNjYtNjUtMTEzLTc3LTQ3LTExNS02Ny0xMTYtNjQgMCAzIDQ2IDI2IDgxIDc0IDM0IDQ5IDI2IDg3IDI1IDkwIDAgMy01IDQyLTU0IDc2LTQ4IDM0LTEwMCA0MC0xMDAgNDMtMSAzIDQyLTMgMTMwLTIyIDg3LTE5IDk2LTY4IDk5LTg0IiBmaWxsPSIjREIwQjVCIi8+PHBhdGggZD0iTTE2NSAxMDZjLTE2IDUtNjIgMjMtNjUgMTEyLTIgOTAgMCAxMzMgMiAxMzIgMy0xLTEtNTMgMjQtMTA3IDI1LTUzIDYyLTY1IDY1LTY2czM5LTE3IDkzIDggODUgNjcgODggNjZjMi0xLTI0LTM1LTg0LTEwMi02MC02Ni0xMDctNDktMTIzLTQzIiBmaWxsPSIjREIwQjVCIi8+PHBhdGggZD0iTTE0OCA1MjNjMTMgMTAgNTEgNDIgMTMwIDAgNzktNDMgMTE2LTY3IDExMy02OC0yLTItNDUgMjctMTA0IDMyLTU5IDYtODgtMjEtOTAtMjMtMy0yLTM0LTI1LTM5LTg0LTYtNTkgMTUtMTA3IDEzLTEwOXMtMTkgMzgtNDYgMTI0Yy0yOCA4NSAxMCAxMTcgMjMgMTI4IiBmaWxsPSIjREIwQjVCIi8+PHBhdGggZD0iTTIxNyAzNzVjMCAyNyAyMyAzNSA0MSAyNGw5OC01NmMyMi0xMyAxOC0zNyAwLTQ4bC05OC01NmMtMjItMTMtNDEgMi00MSAyNCIgZmlsbD0iI2YwZjBmMCIvPjwvc3ZnPg=="
//const feoSymbolMini_ratio:number = 0.959
const feoSymbolMini_ratio:number = 0.82
const feoSymbolMini_lightBg= "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjUwIDUwIDUyMyA1NDMiPjxwYXRoIGQ9Ik01MTggMzI5YzMtMTcgMTEtNjYtNjUtMTEzLTc3LTQ3LTExNS02Ny0xMTYtNjQgMCAzIDQ2IDI2IDgxIDc0IDM0IDQ5IDI2IDg3IDI1IDkwIDAgMy01IDQyLTU0IDc2LTQ4IDM0LTEwMCA0MC0xMDAgNDMtMSAzIDQyLTMgMTMwLTIyIDg3LTE5IDk2LTY4IDk5LTg0IiBmaWxsPSIjREIwQjVCIi8+PHBhdGggZD0iTTE2NSAxMDZjLTE2IDUtNjIgMjMtNjUgMTEyLTIgOTAgMCAxMzMgMiAxMzIgMy0xLTEtNTMgMjQtMTA3IDI1LTUzIDYyLTY1IDY1LTY2czM5LTE3IDkzIDggODUgNjcgODggNjZjMi0xLTI0LTM1LTg0LTEwMi02MC02Ni0xMDctNDktMTIzLTQzIiBmaWxsPSIjREIwQjVCIi8+PHBhdGggZD0iTTE0OCA1MjNjMTMgMTAgNTEgNDIgMTMwIDAgNzktNDMgMTE2LTY3IDExMy02OC0yLTItNDUgMjctMTA0IDMyLTU5IDYtODgtMjEtOTAtMjMtMy0yLTM0LTI1LTM5LTg0LTYtNTkgMTUtMTA3IDEzLTEwOXMtMTkgMzgtNDYgMTI0Yy0yOCA4NSAxMCAxMTcgMjMgMTI4IiBmaWxsPSIjREIwQjVCIi8+PHBhdGggZD0iTTIxNyAzNzVjMCAyNyAyMyAzNSA0MSAyNGw5OC01NmMyMi0xMyAxOC0zNyAwLTQ4bC05OC01NmMtMjItMTMtNDEgMi00MSAyNCIgZmlsbD0iIzIxMjEyMSIvPjwvc3ZnPg=="
export function getFeoSymbol(size:number = 10,
                             paddingLeft:number= 12)
{
    const width =size
    const height = size * feoSymbolMini_ratio
    const hH = Math.round(height / 2)
    return [
        //'padding: ' +heightHalf+ 'px ' + '100 px ' +heightHalf+ 'px '+ 100 +'px;',
        'padding: '+hH+'px 0px '+hH+'px '+(width+paddingLeft)+'px;',
        'background: url(' + feoSymbolMini_darkBg + ') no-repeat;',
        'background-size: contain;', //or contain...?
    ].join(' ')
}