import {i18nLocalNumberFormatsSet} from '../types';

export const numberFormatsI18n:i18nLocalNumberFormatsSet =
{
    'ja-JP': {
        currency: {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: 'JPY'
        }
    },
    'cs-CZ': {
        currency: {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: 'CZK'
        },
        
    },
    'en-US': {
        currency: {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: 'USD'
        },
    }
}