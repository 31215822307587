// services/ApiService.ts
import axios, {AxiosHeaders, AxiosInstance} from 'axios';
import { IApiService } from './IApiService';

/**
 * General axios JSON service
 */
class ApiService implements IApiService {
    protected baseURL: string;
    protected axiosClient: AxiosInstance;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
        this.axiosClient = axios.create({
            baseURL: baseURL,
            headers: new AxiosHeaders({
                    'Content-Type': 'application/json',
                }
            ),
        });
    }

    public getAxiosInstance(): AxiosInstance {
        return this.axiosClient;
    }
}

export default ApiService;
