import { parse as fnsParse, format as fnsFormat, Locale as FnsLocale } from 'date-fns';
import {LocaleLang, LocalePackId, LocaleLangTag} from '../types';
import LoggerInterface from "../../logging/input/LoggerInterface";

export class DateFnsManager
{
    private currentLocaleFnsPack: FnsLocale|null = null
    private logger: LoggerInterface;
    constructor(
        activeLocaleFull: LocaleLangTag,
        logger: LoggerInterface
    ) {
        this.logger = logger.getSubLogger('fns', null)
    }

    private locale2LocalePackId(localeFull: LocaleLangTag): LocalePackId
    {
        //test for long variant:
        switch (localeFull) {
            case 'en-US':
                return localeFull
        }
        //test for short variant:
        const boom = localeFull.split('-')
        const localeLang:LocaleLang = boom[0]
        return localeLang
    }

    private async loadLocalePack(localeFull: LocaleLangTag): Promise<FnsLocale>{
        const langPackId = this.locale2LocalePackId(localeFull)
        //dynamic imports - requires rollup-plugin-dynamic-import-vars
        const moduleLoad = import(`./../../../node_modules/date-fns/locale/${langPackId}.mjs`)
        return moduleLoad.then((module: { default: FnsLocale }) => {
            const localePack = module.default
            this.logger.debug('Loaded fns locale pack: ', localePack)
            return localePack
        }).catch((err: any) => {
            throw new Error('Failed to load fns locale pack: ' + langPackId + ', error: ' + err)
        })
    }

    public async setLocale(localeFull: LocaleLangTag): Promise<void>
    {
        const localePack = await this.loadLocalePack(localeFull)
        this.currentLocaleFnsPack = localePack
        this.logger.debug('DateFnsManager locale set: ', localePack)
    }

    public get(): FnsLocale
    {
        if (!this.currentLocaleFnsPack) {
            throw new Error('Locale not set')
        }
        return this.currentLocaleFnsPack
    }

    public format(date: Date, format: string): string
    {
        if (!this.currentLocaleFnsPack) {
            throw new Error('Locale not set')
        }
        return fnsFormat(date, format,
           { locale: this.currentLocaleFnsPack }
        );
    }


    public parse(dateString: string, format:string, baseDate: Date = new Date()): Date
    {
        if (!this.currentLocaleFnsPack) {
            throw new Error('Locale not set')
        }
        const input = dateString
            .replace(/(\D)\s+(\d)/g, '$1$2')
            .replace(/(\d)\s+(\D)/g, '$1$2')
        ;
        const dd = fnsParse(input, format, baseDate,
            { locale: this.currentLocaleFnsPack }
        );
        //if invalid
        if (isNaN(dd.getTime())) {
            throw new Error('Invalid date: ' + dateString + ', format: ' + format)
        }
        return dd
    }
}

export default DateFnsManager