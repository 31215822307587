import LoggerInterface from "./input/LoggerInterface";
import {LogLevel} from "./LogLevel";
import LogOutputInterface from "./output/LogOutputInterface";
import {Context} from "./Context";
import GenericLogger from "./input/GenericLogger";
import GlobalConsole from "./output/Global/GlobalConsole";


export class LoggingManager {
    private output: LogOutputInterface
    private globalLogger: LoggerInterface;
    private coreLogger: LoggerInterface;

    constructor(output:LogOutputInterface, hookGlobalConsoleFns: boolean = true)
    {
        this.output = output
        this.globalLogger = this.createLogger(new Context('global'), LogLevel.DEBUG)
        this.coreLogger = this.createLogger(new Context('core'), LogLevel.DEBUG)

        if (hookGlobalConsoleFns)
        {
            const globalLogger =
                this.globalLogger.getSubLogger('console', LogLevel.DEBUG)

            GlobalConsole.setLogger(globalLogger)
        }
    }

    public createLogger(context: Context, logLevelLimit: LogLevel|null =null):LoggerInterface
    {
        return new GenericLogger(this.output, context, logLevelLimit ?? LogLevel.DEBUG)
    }

    public getLogger(contextName: string, logLevelLimit: LogLevel|null =null):LoggerInterface
    {
        return this.globalLogger.getSubLogger(contextName, logLevelLimit)
    }

    public getCoreLogger(contextName: string, logLevelLimit: LogLevel|null =null):LoggerInterface
    {
        return this.coreLogger.getSubLogger(contextName, logLevelLimit)
    }
}

export default LoggingManager;