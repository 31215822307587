import {CoreEventListener} from "../../internals/EventEmitter";

export enum LoginServiceEventNamesEnum {
    loggedIn = 'loggedIn',
    loggedOut = 'loggedOut'
}
export type LoginServiceLoggedIn = CoreEventListener<object>
export type LoginServiceLoggedOut = CoreEventListener<object>

export interface ILoginService {
    login(credentials: { username: string, password: string }): Promise<void>;
    logout(): void;
    isAuthenticated(): boolean;

    onLoggedIn(callback: LoginServiceLoggedIn): void;
    onLoggedOut(callback: LoginServiceLoggedOut): void;
}