
import {EnvVar, EnvVars} from "../types";

export class ConfigEnvReader {
    private readonly processEnv: EnvVars

    constructor(_processEnv: EnvVars) {
        this.processEnv = _processEnv
    }

    setEnvVariable(key: string, value: EnvVar): void {
        this.processEnv[key] = value
    }

    getEnvVariableString(key: string, defaultValue: string): string {
        return this.getEnvVariable(key, defaultValue) as string
    }

    getEnvVariable(key: string, defaultValue: string): EnvVar {
        return  this.processEnv && this.processEnv[key] ? this.processEnv[key] : defaultValue
    }

    getEnvPublicPathBase(): string {
        const r = this.getEnvVariableString('VUE_ROUTER_BASE', '/')
        if (r) {
            return r
        }
        return '/'
    }
}